// Servis Çağrıları
import {ApiService} from "../Base";
import * as Endpoint from "../../Config/EndpointUrl";

import Helper from "../../../Helper";

class WorkOrder {
    constructor() {
    }

    /**
     * Work Order Ekleme
     * @param {string} WorkOrderTypeId - 1- Analiz 2- Gözetim 3- hepsi
     * @param {string} WorkOrderName - Gemi Adı
     * @param {string} WorkOrderCustomers - Müşteriler ID
     * @param {string} WorkOrderOperationId - Operasyom Id
     * @param {Date} WorkOrderDate - Gemi Tarihi
     * @param {object} WorkOrderSample - {quantity:Numune Miktarı,quantity_type:Ölçüsü,pickup_location:Alım Yeri}
     * @param {Array} WorkOrderLaboratories - {labCode,labId,{productId}:Products,{type,sample_test_id}:sample_tests}
     * Laboratuvar , Ürünler , Test ve Test Paketleri
     * @param {Array} WorkOrderRegions - {regionId,{productId}:Products} Gözetim Kısmı
     */
    Create = ({
                  WorkOrderTypeId,
                  workOrderUrgency,
                  WorkOrderName,
                  WorkOrderCustomers,
                  WorkOrderOperationId,
                  WorkOrderDate,
                  get_detail,
                  WorkOrderSample,
                  WorkOrderLaboratories,
                  WorkOrderRegions,
                  WorkOrderSectorId,
                  WorkOrderPermID,
                  WorkOrderDraftReport,
              }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const requestParams = {
                    work_order_type_id: WorkOrderTypeId,
                    get_detail: get_detail,

                    workOrderUrgency: workOrderUrgency,
                    customers: WorkOrderCustomers,
                    operation_id: WorkOrderOperationId,
                    date: WorkOrderDate,
                    sample: WorkOrderSample,
                    laboratories: WorkOrderLaboratories,
                    regions: WorkOrderRegions,
                    sector_id: WorkOrderSectorId,
                    perm_id: WorkOrderPermID,
                    draft_report: WorkOrderDraftReport ? 1 : 0,
                };
                console.log("requestPAramsWorkOrderTypeId", requestParams);
                if (WorkOrderTypeId == 2) {
                    //  delete requestParams.sample;
                    delete requestParams.laboratories;
                }

                const response = await ApiService(
                    "post",
                    Endpoint.WorkOrder.create,
                    requestParams
                );
                let result = {
                    status: false,
                    message: "Bir hata meydana geldi",
                };
                if (response.status) {
                    result = {
                        status: true,
                        message: response.message,
                    };
                } else {
                    result.message = response.message;
                }
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    };
    /**
     * Work Order  Düzenleme
     * @param {string} WorkOrderReportNo - Rapor Numarası
     * @param {string} WorkOrderTypeId - 1- Analiz 2- Gözetim 3- hepsi
     * @param {string} WorkOrderName - Gemi Adı
     * @param {string} WorkOrderCustomers - Müşteriler ID
     * @param {string} WorkOrderOperationId - Operasyom Id
     * @param {Date} WorkOrderDate - Gemi Tarihi
     * @param {object} WorkOrderSample - {quantity:Numune Miktarı,quantity_type:Ölçüsü,pickup_location:Alım Yeri}
     * @param {Array} WorkOrderLaboratories - {labCode,labId,{productId}:Products,{type,sample_test_id}:sample_tests}
     * Laboratuvar , Ürünler , Test ve Test Paketleri
     * @param {Array} WorkOrderRegions - {regionId,{productId}:Products} Gözetim Kısmı
     */
    Update = ({
                  WorkOrderReportNo,
                  WorkOrderTypeId,
                  workOrderUrgency,
                  WorkOrderName,
                  get_detail,
                  WorkOrderCustomers,
                  WorkOrderOperationId,
                  WorkOrderDate,
                  WorkOrderSample,
                  WorkOrderLaboratories,
                  WorkOrderRegions,
                  WorkOrderSectorId,
                  WorkOrderPermID,
                  WorkOrderDraftReport,
              }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const requestParams = {
                    report_no: WorkOrderReportNo,
                    work_order_type_id: WorkOrderTypeId,
                    workOrderUrgency: workOrderUrgency,
                    get_detail: get_detail,
                    customers: WorkOrderCustomers,
                    operation_id: WorkOrderOperationId,
                    date: WorkOrderDate,
                    sample: WorkOrderSample,
                    laboratories: WorkOrderLaboratories,
                    regions: WorkOrderRegions,
                    sector_id: WorkOrderSectorId,
                    perm_id: WorkOrderPermID,
                    draft_report: WorkOrderDraftReport ? 1 : 0,
                };

                const response = await ApiService(
                    "put",
                    Endpoint.WorkOrder.update,
                    requestParams
                );
                let result = {
                    status: false,
                    message: "Bir hata meydana geldi",
                };
                if (response.status) {
                    result = {
                        status: true,
                        message: response.message,
                    };
                } else {
                    result.message = response.message;
                }
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    };

    /**
     * Work Order Listeleme
     * @param {number} limit
     * @param {number} page
     * @param {number} startDate
     * @param {number} endDate
     */
    SelectAll = (limit = 0, page = 0, startDate = 0, endDate = 0) => {
        return new Promise(async (resolve, reject) => {
            try {
                const endpoints = `${Endpoint.WorkOrder.getAll}/${limit}/${page}/${startDate}/${endDate}`;
                const response = await ApiService("get", endpoints);

                let result = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                    response: {
                        details: {},
                        data: [],
                    },
                };
                if (response.status) {
                    result = {
                        status: true,
                        message: response.message,
                        response: {
                            details: response.reply.pagination,
                            data: response.reply.posts,
                        },
                    };
                } else {
                    result.message = response.message;
                }
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    };

    /**
     * Work Order Listeleme
     * @param {string} reportNo

     */
    Select = (reportNo) => {
        return new Promise(async (resolve, reject) => {
            try {
                const endpoints = `${Endpoint.WorkOrder.getOne}/${reportNo}`;
                const response = await ApiService("get", endpoints);
                let result = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                    response: {
                        details: {},
                        data: [],
                    },
                };
                if (response.status) {
                    result = {
                        status: true,
                        message: response.message,
                        data: response.reply,
                    };
                } else {
                    result.message = response.message;
                }
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    };
    Follow = (workOrderId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const requestParams = {workOrderId};

                const response = await ApiService(
                    "post",
                    Endpoint.Dashboard.followworkordercreate,
                    requestParams
                );
                let result = {
                    status: false,
                    message: "Bir hata meydana geldi",
                };
                if (response.status) {
                    result = {
                        status: true,
                        message: response.message,
                    };
                } else {
                    result.message = response.message;
                }
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    };
    /**
     * Work Order Listeleme
     * @param {string} workorderId

     */
    Details = (workorderId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const endpoints = `${Endpoint.WorkOrder.details}/${workorderId}`;
                const response = await ApiService("get", endpoints);
                let result = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                    response: {
                        details: {},
                        data: [],
                    },
                };
                if (response.status) {
                    result = {
                        status: true,
                        message: response?.message,
                        data: response?.reply,
                    };
                } else {
                    result.message = response.message;
                }
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    };
    /**
     *
     * @param {string} workOrderId - İş emri numarası
     * @param {string} comments - Açıklama
     * @param {number} date - Unix tarih formatı
     * @param {Array} images - Seçilen Resimler
     * @returns
     */
    workFinished = ({comments, date, images, workOrderId}) => {
        return new Promise(async (resolve, reject) => {
            try {
                const requestForm = new FormData();
                requestForm.append("description", comments);
                requestForm.append("work_order_id", workOrderId);
                requestForm.append("finish_date", date);
                for (const key in images) {
                    if (images.hasOwnProperty(key)) {
                        const element = images[key];
                        requestForm.append("item_file[]", element.image, element.name);
                    }
                }
                const response = await ApiService(
                    "post",
                    Endpoint.WorkOrder.workfinished,
                    requestForm,
                    true
                );
                let result = {
                    status: false,
                    message: "Bir hata meydana geldi",
                };
                if (response.status) {
                    result = {
                        status: true,
                        message: response.message,
                        data: response.reply,
                    };
                } else {
                    result.message = response.message;
                }
                resolve(result);
            } catch (error) {
                console.log("Workorder-workFinished-error", {error});
                reject(error);
            }
        });
    };

}

export default new WorkOrder();
