import React, {Component} from "react";
import "../index.css";
import {NavLink} from "react-router-dom";

import PageHeader from "../../../../Components/UI/PageHeader";
import {Button, Card, Space, Spin, Steps} from "antd";

import _ from "lodash";
import moment from "moment";
import DataService from "../../../../Provider/Service/DataService";
import Helper from "../../../../Helper";
import Registration from "./Registration";
import Sample from "./Sample";
import Analysis from "./Analysis";
import Confirmation from "./Confirmation";
import Result from "./Result";

const {Step} = Steps;

// img upload
function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

const toBase64 = (url) =>
    fetch(url)
        .then((response) => response.blob())
        .then(
            (blob) =>
                new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        resolve(reader.result);
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                })
        )
        .catch((err) => console.log("fetcherr", err));

class index extends Component {
    state = {
        stepCurrent: null,
        loading: true,
        customerData: [],
        sectorData: [],
        operationData: [],
        testData: [],
        testpackData: [],
        testsData: [],
        testspackData: [],
        labData: [],
        locationData: [],
        partData: [],
        workOrderData: [],
        storeData: [],
        personCustomerData: [],
        personData: [],
        userData: [],
        methodData: [],
        productData: [],
        formData: {
            WorkOrderSectorId: null,
            WorkOrderProductId: null,
        },
        formTextData: {},
        result: false,
        SampleId: null,
        selectedWorkBranch: {},
        havetrendNo: 0
    };

    componentDidMount() {
        this.getData();
    }

    sectorChangeSet = async (sectorId) => {
        try {
            const responseGetPart = await DataService.Sample.getPart(sectorId);
            this.setState({workOrderData: responseGetPart?.data || []});
            const responseGetPartCat = await DataService.Sample.getPartCat();
            this.setState({partData: responseGetPartCat?.data || []});

            let type = 1;
            if (sectorId === '31479c2a2efc8bd273259ea67f4f5fdc') {
                type = 0;
            }
            const responseMethod = await DataService.Sample.methodSelect(type);
            this.setState({methodData: responseMethod?.data || []});

        } catch (error) {
            Helper.ErrorMessage(error);
        }

        console.log('sectorId', sectorId);
    }

    getData = async () => {
        try {
            const {
                match: {params},
            } = this.props;
            const responseDetails = await DataService.Sample.Select(params.sampleId);
            console.log('responseDetails', responseDetails);
            if (!responseDetails.status) {
                Helper.ErrorMessage(responseDetails.message);
                this.props.history.goBack();
                return;
            }
            const responseCustomers = await DataService.Customer.SelectAll();
            const responseSector = await DataService.Sector.SelectAll();
            const responseOperation = await DataService.Operation.SelectAll();
            const responseTests = await DataService.Test.SelectAll();
            const responseTestPacks = await DataService.TestPack.SelectAll();
            const responseLabs = await DataService.Laboratory.SelectAll();
            const responseGetLocation = await DataService.Sample.getLocation();
            const responseGetPart = await DataService.Sample.getPart(responseDetails?.data?.work_order?.sector_id);
            const responseGetPartCat = await DataService.Sample.getPartCat(responseDetails?.data?.work_order?.sector_id);

            const responseUser = await DataService.User.SelectAll(
                "f54a19e2d81c5bd09024a31cffe295b0"
            );
            const responseStore = await DataService.Store.SelectAll();
            const responseMethod = await DataService.Sample.methodSelect((responseDetails?.data?.work_order?.sector_id === '31479c2a2efc8bd273259ea67f4f5fdc' ? 0 : 1));
            const responsePerson = await DataService.Sample.SelectPerson();
            const responsePersonCustomer = await DataService.Sample.SelectCustomer();
            const responseProduct = await DataService.Product.SelectAll();
            if (
                responseDetails.status &&
                responseCustomers.status &&
                responseSector.status &&
                responseOperation.status &&
                responseTests.status &&
                responseTestPacks.status &&
                responseLabs.status &&
                responseUser.status &&
                responseStore.status &&
                responseMethod.status &&
                responsePerson.status &&
                responsePersonCustomer.status &&
                responseGetPartCat.status &&
                responseProduct.status
            ) {
                const formData = await this.getFormData(responseDetails.data);
                this.setState({
                    customerData: responseCustomers?.response?.data || [],
                    sectorData: responseSector?.response?.data || [],
                    operationData: responseOperation?.response?.data || [],
                    testData: responseTests?.response?.data || [],
                    testpackData: responseTestPacks?.response?.data || [],
                    testsData: responseTests?.response?.data || [],
                    testspackData: responseTestPacks?.response?.data || [],
                    labData: responseLabs?.response?.data || [],
                    locationData: responseGetLocation?.data || [],
                    partData: responseGetPartCat?.data || [],
                    workOrderData: responseGetPart?.data || [],
                    storeData: responseStore?.response?.data || [],
                    personCustomerData: responsePersonCustomer?.data || [],
                    personData: responsePerson?.data || [],
                    userData: responseUser?.data || [],
                    methodData: responseMethod?.data || [],
                    productData: responseProduct?.response?.data || [],
                    havetrendNo: responseDetails?.data?.sample?.havetrend_no,

                    formData,
                    loading: false,
                    stepCurrent: 0,
                    SampleId: params.sampleId,
                });
            } else {
                if (!responseCustomers.status)
                    Helper.ErrorMessage(responseCustomers.message);
                if (!responseSector.status) Helper.ErrorMessage(responseSector.message);
                if (!responseStore.status) Helper.ErrorMessage(responseStore.message);
                if (!responseTests.status) Helper.ErrorMessage(responseTests.message);
                if (!responseLabs.status) Helper.ErrorMessage(responseLabs.message);
                if (!responseUser.status) Helper.ErrorMessage(responseUser.message);
                if (!responseGetLocation.status)
                    Helper.ErrorMessage(responseGetLocation.message);
                if (!responseGetPart.status)
                    Helper.ErrorMessage(responseGetPart.message);

                if (!responseTestPacks.status)
                    Helper.ErrorMessage(responseTestPacks.message);
                if (!responseOperation.status)
                    Helper.ErrorMessage(responseOperation.message);
            }
        } catch (error) {
            Helper.ErrorMessage(error);
        }
    };
    getFormData = (detailsData) => {
        return new Promise(async (resolve, reject) => {
            try {
                const {sample_tests} = detailsData.sample;
                let SampleTestData = [];
                for (const key in sample_tests) {
                    if (sample_tests.hasOwnProperty(key)) {
                        const element = sample_tests[key];
                        SampleTestData.push(element.sample_test_id);
                    }
                }
                let stockName = {},
                    sampleStock = [];
                for (const key in detailsData?.sample_stock) {
                    if (detailsData?.sample_stock.hasOwnProperty(key)) {
                        const element = detailsData?.sample_stock[key];

                        const keySample = `sampleAmount-${parseInt(key) + 1}`;

                        let obje = {};
                        for (const keys in element) {
                            if (keys == "top_store_part_id") {
                                Object.assign(obje, {
                                    [`${keySample}-endless_storage`]: element[keys],
                                });
                            } else if (keys == "top_store_part_id") {
                                Object.assign(obje, {
                                    [`${keySample}-endless_storage`]: element[keys],
                                });
                            } else {
                                Object.assign(obje, {
                                    [`${keySample}-${keys}`]: element[keys],
                                });
                            }
                        }
                        Object.assign(stockName, obje);
                    }
                }
                let fileList = [];
                for (const key in detailsData.sample_photo) {
                    if (detailsData.sample_photo.hasOwnProperty(key)) {
                        const element = detailsData.sample_photo[key];
                        fileList.push({
                            uid: key,
                            name: element.photo,
                            status: "done",
                            url: `https://apilims.intertekturkey.com/getImage/${element.photo}`,
                        });
                    }
                }

                const formData = {
                    EquipmentHour: (detailsData?.sample?.equipment_hours > 0 ? detailsData?.sample?.equipment_hours : '-'),
                    OilHour: (detailsData?.sample?.oil_hours > 0 ? detailsData?.sample?.oil_hours : '-'),
                    WorkOrderName: detailsData?.sample?.get_part,
                    WorkOrderCustomerId: detailsData?.work_order?.customer_id,
                    WorkOrderProductId: detailsData?.work_order?.product_id,
                    WorkOrderSectorId: detailsData?.work_order?.sector_id,
                    WorkOrderOperationId: detailsData?.work_order?.operation_id,
                    WorkOrderTypeId: detailsData?.work_order?.work_order_type_id,
                    SampleLocation: detailsData?.sample?.get_location,
                    SamplePart: detailsData?.sample?.get_part_cat,
                    get_detail: detailsData?.sample?.get_detail,

                    SampleDateDelivery: detailsData?.sample?.date_delivery
                        ? moment(detailsData?.sample?.date_delivery)
                        : undefined,
                    SampleDateDeliveryHour: detailsData?.sample?.date_delivery_hour
                        ? moment(parseInt(detailsData?.sample?.date_delivery_hour) * 1000)
                        : undefined,
                    SampleUserId: detailsData?.sample?.get_user_id,
                    SampleUserCustomerId: detailsData?.sample?.get_user_customer_id,
                    SampleSenderName: detailsData?.sample?.get_sender_name,
                    SampleSenderCustomer:
                        detailsData?.sample?.get_sender_customer || "N/A",
                    SampleMethod: detailsData?.sample?.get_method,
                    SampleAcceptanceDate: detailsData?.sample?.get_acceptance_date
                        ? moment(detailsData?.sample?.get_acceptance_date)
                        : moment(),
                    SampleAcceptanceHour: detailsData?.sample?.get_acceptance_hour
                        ? moment(parseInt(detailsData?.sample?.get_acceptance_hour) * 1000)
                        : undefined,
                    SampleCount: detailsData?.sample?.get_sample_piece,
                    SampleTests: SampleTestData,
                    SampleStock: detailsData?.sample_stock,
                    SampleProblem: detailsData?.sample?.problem == 1 ? true : false,
                    SampleProblemComment: detailsData?.sample?.comment_problem || "",
                    SampleDraft: detailsData?.sample?.draft_report == 1 ? true : false,
                    SampleTestCount: detailsData?.sample?.sample_test_count,
                    SampleMultiple: Number(
                        !Boolean(detailsData?.sample?.repeat_report || 0)
                    ),
                    images: fileList,
                    ...stockName,
                };

                resolve(formData);
            } catch (error) {
                reject(error);
            }
        });
    };
    next = ({formData, formTextData, selectedWorkBranch}) => {
        this.setState({
            stepCurrent: this.state.stepCurrent + 1,
            formData: {
                ...this.state.formData,
                ...formData,
            },
            formTextData: {
                ...this.state.formTextData,
                ...formTextData,
            },
            selectedWorkBranch: selectedWorkBranch
        });
    };
    previous = (formData, selectedWorkBranch) => {
        this.setState({
            stepCurrent: this.state.stepCurrent - 1,
            formData: {
                ...this.state.formData,
                ...formData,
            },
            selectedWorkBranch: selectedWorkBranch
        });
    };
    onFinish = async () => {
        console.log('onFinishSampleEdit', this.state);
        this.setState({loading: true});
        let images = [];
        let SampleTestData = [];
        let SampleTestCount = this.state?.formData?.SampleTestCount || 0;
        for (const key in this.state.formData.images) {
            if (this.state.formData.images.hasOwnProperty(key)) {
                const element = this.state.formData.images[key];

                try {
                    let image;
                    if (element.url) {
                        image = await toBase64(element.url);
                        image = new Blob([image], {type: "image/jpeg"});
                    } else if (element.originFileObj) {
                        image = await getBase64(element.originFileObj);
                        image = new Blob([image], {type: element.originFileObj.type});
                    } else {
                        image = new Blob([element.url], {type: "image/jpeg"});
                    }

                    images.push({
                        image,
                        name: element.name,
                    });
                } catch (error) {
                    console.log("fileList-keys", error);
                }
            }
        }
        if (typeof this.state.formData.SampleTests == "object") {
            for (const key in this.state.formData.SampleTests) {
                if (this.state.formData.SampleTests.hasOwnProperty(key)) {
                    const element = this.state.formData.SampleTests[key];
                    const test = _.find(this.state.testData, {test_id: element});
                    const testpack = _.find(this.state.testpackData, {
                        pack_id: element,
                    });
                    if (test)
                        SampleTestData.push({
                            type: "test",
                            sample_test_id: element,
                            parent_test_id: test.parent_test_id,
                        });
                    if (testpack)
                        SampleTestData.push({
                            type: "test_pack",
                            sample_test_id: element,
                            parent_test_id: "0",
                        });
                }
            }
        } else {
            const test = _.find(this.state.testData, {
                test_id: this.state.formData.SampleTests,
            });
            SampleTestData.push({
                type: "test",
                sample_test_id: this.state.formData.SampleTests,
                parent_test_id: test?.parent_test_id,
            });
        }

        const requestParams = {
            ...this.state.formData,
            SampleId: this.state.SampleId,
            SampleTests: SampleTestData,
            SampleTestCount,
            images,
        };

        DataService.Sample.Update(requestParams)
            .then((result) => {
                if (result.status) {
                    Helper.SuccessMessage(result.message);
                    const newData = [];
                    const {sample_stock, sample_id} = result.data.sample;

                    for (const key in sample_stock) {
                        if (sample_stock.hasOwnProperty.call(sample_stock, key)) {
                            const element = sample_stock[key];
                            newData.push({
                                storage_time_text: element?.stockTime || "",
                                store_id_text: element?.storeName || "",
                                endless_storage_text: element?.StoreTopPartName || "",
                                store_part_id_text: element?.storePartName || "",
                                lab_id_text: element?.laboratoryName || "",
                                sample_stock_id: element?.sample_stock_id || "",
                                type_id: element?.type_id || 1,
                                signet_code: element?.signet_code || "",
                            });
                        }
                    }
                    const datas = {
                        sample_id: sample_id,
                        sample_stock: newData,
                    };

                    this.setState({
                        result: {sample: datas},
                        btnloading: false,
                        loading: false,
                    });
                } else {
                    Helper.ErrorMessage(result.message);
                    this.setState({
                        btnloading: false,
                        loading: false,
                    });
                }
            })
            .catch((err) => Helper.ErrorMessage(err));
    };
    stepContent = () => {
        const {
            stepCurrent,
            customerData,
            sectorData,
            productData,
            operationData,
            workOrderData,
            locationData,
            partData,
            personData,
            personCustomerData,
            methodData,
            labData,
            testData,
            testpackData,
            formData,
            formTextData,
            storeData,
            selectedWorkBranch,
            SampleId
        } = this.state;

        console.log(this.state)

        switch (stepCurrent) {
            case 0:
                return (
                    <Registration
                        next={this.next}
                        data={{
                            customer: customerData,
                            sector: sectorData,
                            product: productData,
                            operation: operationData,
                            workorder: workOrderData,
                            selectedWorkBranch: selectedWorkBranch,
                        }}
                        sectorChange={this.sectorChangeSet}
                        initialValue={formData}
                    />
                );
            case 1:
                return (
                    <Sample
                        next={this.next}
                        previous={this.previous}
                        data={{
                            workorder: workOrderData,
                            location: locationData,
                            part: partData,
                            person: personData,
                            personCustomer: personCustomerData,
                            method: methodData,
                            selectedWorkBranch: selectedWorkBranch,
                            labData,
                            storeData,
                            SampleId
                        }}
                        stockData={this.state.formData.SampleStock}
                        initialValue={formData}
                        havetrendNo={this.state.havetrendNo}
                    />
                );
            case 2:
                return (
                    <Analysis
                        next={this.next}
                        previous={this.previous}
                        data={{
                            test: testData,
                            testPack: testpackData,
                            selectedWorkBranch: selectedWorkBranch
                        }}
                        initialValue={formData}
                    />
                );
            case 3:
                return (
                    <Confirmation
                        previous={this.previous}
                        onFinish={this.onFinish}
                        formTextData={formTextData}
                        data={formData}
                    />
                );

            default:
                return <div/>;
        }
    };

    render() {
        const {stepCurrent, formData, formTextData} = this.state;

        return (
            <div>
                <div className="pageHeaderWrapper">
                    <PageHeader title="Numune Kayıt"/>

                    <Space>
                        <NavLink
                            activeClassName="nav-active"
                            exact
                            to="/sample/sample-registration"
                        >
                            <Button className="btn-list" shape="round">
                                Numune Listesi
                            </Button>
                        </NavLink>
                    </Space>
                </div>
                <Spin spinning={this.state.loading} delay={500}>
                    <Card bordered={false} className="cardTable">
                        {!this.state.result ? (
                            <>
                                <div className="antStepsContainer">
                                    <Steps current={stepCurrent}>
                                        <Step title="Kayıt Bilgileri"/>
                                        <Step title="Numune Bilgileri"/>
                                        <Step title="Analiz Bilgileri"/>
                                        <Step title="Onayla"/>
                                    </Steps>
                                </div>

                                <div className="antStepsContent">{this.stepContent()}</div>
                            </>
                        ) : (
                            <Result
                                data={this.state.result.sample.sample_stock}
                                formData={{
                                    form: formData,
                                    text: formTextData,
                                }}
                            />
                        )}
                    </Card>
                </Spin>
            </div>
        );
    }
}

export default index;
