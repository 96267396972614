import React, {Component} from "react";
import "../index.css";

import {ArrowLeftOutlined, ArrowRightOutlined, CameraOutlined, PlusOutlined, UploadOutlined,} from "@ant-design/icons";

import {
    Button,
    Checkbox,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Row,
    Select,
    Space,
    TimePicker,
    Upload,
} from "antd";
import Helper from "../../../../Helper";
import dateTimeMoment from "moment";
import _ from "lodash";
import SampleAmountItem from "../SampleAmountItem";
import WebCam from "../Webcam";
import DataService from "../../../../Provider/Service/DataService";

const dateTimeFormat = "HH:mm";

const {Option} = Select;

// img upload
function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

const toBase64 = (url) =>
    fetch(url)
        .then((response) => response.blob())
        .then(
            (blob) =>
                new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        resolve(reader.result);
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                })
        )
        .catch((err) => console.log("fetcherr", err));

class Sample extends Component {
    state = {
        trendAnalyticsAvailable: null,
        trendAnalyticsRequested: false,
        showTrendAnalyticsModal: false,
        messageShown: false,
        loading: true,

        fileList: [],
        visible: false,
        previewVisible: false,
        previewTitle: "",
        previewImage: "",
        labData: [],
        storeData: [],
        resultData: {
            shipName: "",
        },
        formData: {
            SampleProblem: false,
            WorkOrderTypeId: 4,
            SampleStock: [],
        },
        sampleAmountData: [{sampleAmount: `sampleAmount-1`}],
        workorder: [],
        location: [],
        part: [],
        person: [],
        personCustomer: [],
        method: [],
        stockData: [],
        selectedWorkBranch: {},
        trendAnalyticsCheck:false
    };
    forms = React.createRef();

    createTrendAnalytics() {
        this.forms.current.setFieldsValue({
            ['trendAnalyticsControl']: true
        });
        this.setState({
            trendAnalyticsRequested: true,
            showTrendAnalyticsModal: false
        })
    }

    cancelTrendAnalytics() {
        this.forms.current.setFieldsValue({
            ['trendAnalyticsControl']: false
        });
        this.setState({
            trendAnalyticsRequested: false,
            showTrendAnalyticsModal: false
        })
    }

    componentDidMount() {
        this._sampleAmountChange('back');
        this.getData();
    }

    getData = () => {
        const {data, initialValue, stockData} = this.props;
        console.log('dataWork',data);
        this.setState({
            loading: false,

            ...data,
            fileList: [],

            formData: {
                ...this.state.formData,
                WorkOrderTypeId: initialValue.WorkOrderTypeId,
                SampleStock: stockData,
            },
            SampleProblem: initialValue.SampleProblem,
        }, () => {
            this.forms.current.setFieldsValue(initialValue);
        });
    };
    // img upload
    handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle:
                file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
        });
    };
    handleChange = ({fileList}) => {
        this.setState({fileList});
    };

    handleCancel = () => this.setState({previewVisible: false});
    previous = () => {
        const formData = this.forms.current.getFieldsValue();
        //SampleCount
        this.props.previous({
            ...formData,
            ...this.props.initialValue
        }, this.state.selectedWorkBranch);
    };
    getFormText = (element) => {
        return new Promise((resolve, reject) => {
            try {
                const {
                    SampleAcceptanceDate,
                    SampleAcceptanceHour,
                    SampleDateDelivery,
                    SampleDateDeliveryHour,
                    SampleCount,
                    SampleDraft,
                    SampleLocation,
                    SampleMethod,
                    SamplePart,
                    SampleProblem,
                    SampleSenderCustomer,
                    SampleSenderName,
                    SampleUserCustomerId,
                    SampleUserId,
                    WorkOrderName,
                } = element;
                const formTextData = {
                    SampleAcceptance: `${dateTimeMoment(SampleAcceptanceDate).format(
                        "L"
                    )} - ${dateTimeMoment(SampleAcceptanceHour).format("LT")}`,
                    SampleDateDelivery: `${dateTimeMoment(SampleDateDelivery).format(
                        "L"
                    )} - ${dateTimeMoment(SampleDateDeliveryHour).format("LT")}`,
                    SampleCount: SampleCount,
                    SampleDraft: SampleDraft ? "Evet" : "Hayır",
                    SampleLocation: SampleLocation,
                    SampleMethod: SampleMethod,
                    SamplePart: SamplePart,
                    SampleProblem: SampleProblem ? "Evet" : "Hayır",
                    SampleSenderCustomer: SampleSenderCustomer,
                    SampleSenderName: SampleSenderName,
                    SampleUserCustomerId: SampleUserCustomerId,
                    SampleUserId: SampleUserId,
                    WorkOrderName: WorkOrderName,
                };
                resolve(formTextData);
            } catch (error) {
                reject(error);
            }
        });
    };
    onFormFinish = async (formData) => {
        try {
            const formTextData = await this.getFormText(formData);

            this.props.next({
                formData: {
                    ...formData,
                    trendAnalyticsRequested: this.state.trendAnalyticsRequested,
                    images: this.state.fileList,
                    SampleStock: this.state.SampleStock.slice(0, formData?.SampleCount),
                },
                formTextData,
                selectedWorkBranch: this.state.selectedWorkBranch
            });
        } catch (error) {
            Helper.ErrorMessage(error.toString());
        }
    };

    formInputs = [];

    formFieldsChange(formData) {
        this.formInputs = this.props.initialValue;
        let formProps = Object.keys(this.formInputs);

        formData.forEach((field) => {
            if (!formProps.includes(field.name[0])) {
                this.formInputs[field.name[0]] = field.value;
            } else {
                this.formInputs[field.name[0]] = field.value;
            }
        });

        // "WorkOrderCustomerId", "WorkOrderProductId", "WorkOrderSectorId", "WorkOrderName", "SamplePart"
        if (
            formProps.includes("WorkOrderCustomerId") &&
            formProps.includes("WorkOrderProductId") &&
            formProps.includes("WorkOrderSectorId") &&
            formProps.includes("WorkOrderName") &&
            formProps.includes("SamplePart")
        ) {

            if (
                formData.length > 0 &&
                (
                    formData[0].name[0] === "WorkOrderCustomerId" ||
                    formData[0].name[0] === "WorkOrderProductId" ||
                    formData[0].name[0] === "WorkOrderSectorId" ||
                    formData[0].name[0] === "WorkOrderName" ||
                    formData[0].name[0] === "SamplePart"
                )
            ) {
                DataService.TrendAnalytics.Check({
                    WorkOrderCustomerId: this.formInputs.WorkOrderCustomerId,
                    WorkOrderProductId: this.formInputs.WorkOrderProductId,
                    WorkOrderSectorId: this.formInputs.WorkOrderSectorId,
                    WorkOrderName: this.formInputs.WorkOrderName,
                    SamplePart: this.formInputs.SamplePart
                }).then((result) => {
                    if (result.data.length > 0) {
                        if (!this.state.messageShown) {
                            message.open({
                                type: 'success',
                                content: 'Numune üzerinde Trend Analizi yapılabilir!',
                            });
                        }
                        this.setState({
                            trendAnalyticsAvailable: true,
                            showTrendAnalyticsModal: true,
                            messageShown: true,
                            trendAnalyticsCheck:true
                        });
                        setTimeout(() => {
                            this.setState({
                                messageShown: false
                            });
                        }, 3000)
                    } else {
                        this.setState({
                            trendAnalyticsAvailable: false,
                            trendAnalyticsCheck:false,
                            trendAnalyticsRequested:false
                        })
                    }
                })
            }
        } else {
            this.setState({
                trendAnalyticsAvailable: false
            })
        }
    }

    addItem = ({type, value}) => {
        if (!value) {
            return;
        }
        const {location, part, personCustomer, person, workorder, method} =
            this.state;
        switch (type) {
            case "SampleLocation":
                const newData = [...location];
                newData.push({
                    name: value,
                });
                this.setState({
                    location: newData,
                });

                break;
            case "SamplePart":
                const partData = [...part];
                partData.push({
                    name: value,
                });
                this.setState({
                    part: partData,
                });
                break;
            case "SamplePartAdd":
                const partAddData = [...part];
                partAddData.push({
                    name: value,
                });
                this.setState({
                    part: partAddData,
                });
                break;
            case "SampleUserIdAdd":
                const personData = [...person];
                personData.push({
                    name: value,
                    customer_id: "",
                    region_id: "",
                });
                this.setState({
                    person: personData,
                });
                break;
            case "SampleUserCustomerIdAdd":
                const personCustomerData = [...personCustomer];
                personCustomerData.push({
                    name: value,
                    customer_id: "",
                });
                this.setState({
                    personCustomer: personCustomerData,
                });

                break;
            case "WorkOrderName":
                const workorderData = [...workorder];
                workorderData.push({
                    name: value,
                });
                this.setState({
                    workorder: workorderData,
                });
                break;
            case "SampleMethod":
                const methodData = [...method];
                methodData.push({
                    name: value,
                });
                this.setState({
                    method: methodData,
                });
                break;

            default:
                break;
        }
    };
    _personChange = (item, options) => {
        const SampleUserCustomerId =
            _.find(this.state.personCustomer, {
                customer_id: options.customerId,
            })?.name || "N/A";
        this.forms.current.setFieldsValue({
            SampleUserCustomerId,
        });
    };
    _senderCustomerChange = (item, options) => {
        const SampleSenderCustomer =
            _.find(this.state.personCustomer, {
                customer_id: options.customerId,
            })?.name || "N/A";
        this.forms.current.setFieldsValue({
            SampleSenderCustomer,
        });
    };
    _sampleAmountChange = (value) => {
        if(value === 'back'){
            value = (this.props?.initialValue?.SampleCount ?? 1);
        }
        if (value >= 0 || 20 <= value) {
            let sampleAmountData = [];
            for (let key = 0; key < value; key++) {
                sampleAmountData.push({sampleAmount: `sampleAmount-${key + 1}`});
            }
            this.setState({
                sampleAmountData
            });
        } else {
            Helper.warningMessage("Lütfen 1 ile 20 arasında bir değer giriniz.");
        }
    };

    isOCM() {
        return this.props.data.selectedWorkBranch.hasOwnProperty('name') && (this.props.data.selectedWorkBranch.name === 'LUBOIL (OCM)');
    }

    render() {
        const {
            loading,

            fileList,
            previewTitle,
            previewVisible,
            previewImage,
            workorder,
            location,
            part,
            person,
            personCustomer,
            method,
            labData,
            storeData,
            formData,
            SampleProblem,
            trendAnalyticsRequested,
        } = this.state;
        if (loading) {
            return <div/>;
        }
        const uploadButton = (
            <div>
                <UploadOutlined/>
                <div className="ant-upload-text">Yükle</div>
            </div>
        );
        const SampleAmountItems = this.state.sampleAmountData.map((item, index) => {
            return (
                <SampleAmountItem
                    key={index}
                    sampleAmount={item.sampleAmount}
                    data={this.state.formData.SampleStock.length > 0 ? this.state.formData.SampleStock[index] : null}
                    labData={labData}
                    storeData={storeData}
                    WorkOrderTypeId={formData.WorkOrderTypeId}
                    onChange={(items) => {
                        let {SampleStock} = formData;
                        SampleStock[index] = items;
                        this.setState({
                            SampleStock,
                        });
                    }}
                />
            );
        });

        return (
            <>
                <Form
                    layout="vertical"
                    hideRequiredMark
                    onFinish={this.onFormFinish}
                    onFieldsChange={(evt) => this.formFieldsChange(evt)}
                    ref={this.forms}
                >
                    <Row gutter={16}>
                        <Col md={6}>
                            <Form.Item
                                name="WorkOrderName"
                                label={this.isOCM() ? 'Makina' : 'Numunenin Alındığı Yer / Sample At'}
                                rules={[
                                    {
                                        required: true,
                                        message: "Lütfen bu alanı boş bırakmayın",
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder=""
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLocaleLowerCase()
                                            .indexOf(input.toLocaleLowerCase()) >= 0
                                    }
                                    dropdownRender={(menu) => (
                                        <div>
                                            {menu}
                                            <Divider style={{margin: "4px 0"}}/>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexWrap: "nowrap",
                                                    padding: 8,
                                                }}
                                            >
                                                <Input
                                                    style={{flex: "auto"}}
                                                    ref={(refs) => (this.WorkOrderName = refs)}
                                                />
                                                <a
                                                    style={{
                                                        flex: "none",
                                                        padding: "8px",
                                                        display: "block",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                        this.addItem({
                                                            type: "WorkOrderName",
                                                            value: this.WorkOrderName.state?.value || null,
                                                        })
                                                    }
                                                >
                                                    <PlusOutlined/> Ekle
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                >
                                    {workorder.map((item, index) => {
                                        return (
                                            <Option key={index} value={item.name}>
                                                {item.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        {!this.isOCM() &&
                            <Col md={6}>
                                <Form.Item
                                    name="SampleLocation"
                                    label="Terminal / Terminal"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Lütfen bu alanı boş bırakmayın",
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder=""
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLocaleLowerCase()
                                                .indexOf(input.toLocaleLowerCase()) >= 0
                                        }
                                        dropdownRender={(menu) => (
                                            <div>
                                                {menu}
                                                <Divider style={{margin: "4px 0"}}/>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexWrap: "nowrap",
                                                        padding: 8,
                                                    }}
                                                >
                                                    <Input
                                                        style={{flex: "auto"}}
                                                        ref={(refs) => (this.SampleLocationText = refs)}
                                                    />
                                                    <a
                                                        style={{
                                                            flex: "none",
                                                            padding: "8px",
                                                            display: "block",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                            this.addItem({
                                                                type: "SampleLocation",
                                                                value:
                                                                    this.SampleLocationText.state?.value || null,
                                                            })
                                                        }
                                                    >
                                                        <PlusOutlined/> Ekle
                                                    </a>
                                                </div>
                                            </div>
                                        )}
                                    >
                                        {location.map((item, index) => {
                                            return (
                                                <Option key={index} value={item.name}>
                                                    {item.name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        }
                        <Col md={6}>
                            <Form.Item
                                name="SamplePart"
                                label={(this.isOCM() ? 'Parça' : 'Alım Yeri Kategori')}
                                rules={[
                                    {
                                        required: true,
                                        message: "Lütfen bu alanı boş bırakmayın",
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder=""
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLocaleLowerCase()
                                            .indexOf(input.toLocaleLowerCase()) >= 0
                                    }
                                    dropdownRender={(menu) => (
                                        <div>
                                            {menu}
                                            <Divider style={{margin: "4px 0"}}/>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexWrap: "nowrap",
                                                    padding: 8,
                                                }}
                                            >
                                                <Input
                                                    style={{flex: "auto"}}
                                                    ref={(refs) => (this.SamplePartText = refs)}
                                                />
                                                <a
                                                    style={{
                                                        flex: "none",
                                                        padding: "8px",
                                                        display: "block",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                        this.addItem({
                                                            type: "SamplePart",
                                                            value: this.SamplePartText.state?.value || null,
                                                        })
                                                    }
                                                >
                                                    <PlusOutlined/> Ekle
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                >
                                    {part.map((item, index) => {
                                        return (
                                            <Option key={index} value={item.name}>
                                                {item.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                            {
                                this.state.trendAnalyticsCheck && (
                                    <Form.Item
                                        name="trendAnalyticsControl"
                                        valuePropName="checked"
                                        initialValue={trendAnalyticsRequested}
                                    >
                                        <Checkbox
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    this.createTrendAnalytics()
                                                }else {
                                                    this.cancelTrendAnalytics()
                                                }
                                            }}
                                        >
                                            Trend Analizi Yap
                                        </Checkbox>
                                    </Form.Item>
                                )
                            }

                        </Col>
                        <Col md={6}>
                            <Form.Item
                                name="get_detail"
                                label={'Detay Bilgi'}
                                rules={[
                                    {
                                        required: false,
                                        message: "Lütfen bu alanı boş bırakmayın",
                                    },
                                ]}
                            >
                                <Input
                                    style={{flex: "auto"}}
                                />
                            </Form.Item>
                        </Col>
                        <Col md={6}>
                            {this.isOCM() &&
                                <Row gutter={16}>
                                    <Col md={12}>
                                        <Form.Item
                                            name="EquipmentHour"
                                            label="Ekipman Çalışma Saati"
                                            rules={[
                                                {
                                                    required: false,
                                                    message: "Lütfen bu alanı boş bırakmayın",
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                style={{width: "100%"}}
                                                min={1}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Item
                                            name="OilHour"
                                            label="Yağ Çalışma Saati"
                                            rules={[
                                                {
                                                    required: false,
                                                    message: "Lütfen bu alanı boş bırakmayın",
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                style={{width: "100%"}}
                                                min={1}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            }
                        </Col>

                        {this.isOCM() &&
                            <Col md={6}>
                            </Col>
                        }

                        <Col md={6}>
                            <Form.Item
                                name="SampleUserId"
                                label="Numuneyi Alan"
                                rules={[
                                    {
                                        required: true,
                                        message: "Lütfen bu alanı boş bırakmayın",
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder=""
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLocaleLowerCase()
                                            .indexOf(input.toLocaleLowerCase()) >= 0
                                    }
                                    onChange={this._personChange}
                                    dropdownRender={(menu) => (
                                        <div>
                                            {menu}
                                            <Divider style={{margin: "4px 0"}}/>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexWrap: "nowrap",
                                                    padding: 8,
                                                }}
                                            >
                                                <Input
                                                    style={{flex: "auto"}}
                                                    ref={(refs) => (this.SampleUserIdText = refs)}
                                                />
                                                <a
                                                    style={{
                                                        flex: "none",
                                                        padding: "8px",
                                                        display: "block",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                        this.addItem({
                                                            type: "SampleUserIdAdd",
                                                            value: this.SampleUserIdText.state?.value || null,
                                                        })
                                                    }
                                                >
                                                    <PlusOutlined/> Ekle
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                >
                                    {person.map((item) => {
                                        return (
                                            <Option
                                                key={item.index}
                                                value={item.name}
                                                customerId={item.customer_id}
                                            >
                                                {item.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                                {/* (select) ile seçilecek yoksa eklenecek */}
                            </Form.Item>
                        </Col>

                        <Col md={6}>
                            <Form.Item
                                name="SampleUserCustomerId"
                                label="Numuneyi Alan Şirketi"
                                rules={[
                                    {
                                        required: true,
                                        message: "Lütfen bu alanı boş bırakmayın",
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder=""
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLocaleLowerCase()
                                            .indexOf(input.toLocaleLowerCase()) >= 0
                                    }
                                    dropdownRender={(menu) => (
                                        <div>
                                            {menu}
                                            <Divider style={{margin: "4px 0"}}/>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexWrap: "nowrap",
                                                    padding: 8,
                                                }}
                                            >
                                                <Input
                                                    style={{flex: "auto"}}
                                                    ref={(refs) => (this.SampleUserCustomerIdText = refs)}
                                                />
                                                <a
                                                    style={{
                                                        flex: "none",
                                                        padding: "8px",
                                                        display: "block",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                        this.addItem({
                                                            type: "SampleUserCustomerIdAdd",
                                                            value:
                                                                this.SampleUserCustomerIdText.state?.value ||
                                                                null,
                                                        })
                                                    }
                                                >
                                                    <PlusOutlined/> Ekle
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                >
                                    {personCustomer.map((item, index) => {
                                        return (
                                            <Option key={index} value={item.name}>
                                                {item.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                                {/* Numuneyi Alan belliyse Şirketi de bellidir */}
                            </Form.Item>
                        </Col>
                        <Col md={6}>
                            <Form.Item
                                name="SampleMethod"
                                label={this.isOCM() ? 'Yağ Marka' : 'Numune Alma Metodu'}
                                rules={[
                                    {
                                        required: true,
                                        message: "Lütfen bu alanı boş bırakmayın",
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Seçiniz"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLocaleLowerCase()
                                            .indexOf(input.toLocaleLowerCase()) >= 0
                                    }
                                    dropdownRender={(menu) => (
                                        <div>
                                            {menu}
                                            <Divider style={{margin: "4px 0"}}/>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexWrap: "nowrap",
                                                    padding: 8,
                                                }}
                                            >
                                                <Input
                                                    style={{flex: "auto"}}
                                                    ref={(refs) => (this.SampleMethod = refs)}
                                                />
                                                <a
                                                    style={{
                                                        flex: "none",
                                                        padding: "8px",
                                                        display: "block",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                        this.addItem({
                                                            type: "SampleMethod",
                                                            value: this.SampleMethod.state?.value || null,
                                                        })
                                                    }
                                                >
                                                    <PlusOutlined/> Ekle
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                >
                                    {method.map((item, index) => {
                                        return (
                                            <Option key={index} value={item.name}>
                                                {item.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col md={3}>
                            <Form.Item
                                name="SampleDateDelivery"
                                label="Numunenin Alma Tarihi"
                                rules={[]}
                            >
                                <DatePicker
                                    style={{width: "100%"}}
                                    placeholder="Tarih Seçiniz"
                                />
                            </Form.Item>
                        </Col>
                        <Col md={3}>
                            <Form.Item name="SampleDateDeliveryHour" label="Saat" rules={[]}>
                                <TimePicker
                                    format={dateTimeFormat}
                                    placeholder="Saat Seçiniz."
                                />
                            </Form.Item>
                        </Col>
                        <Divider/>

                        <Col md={6}>
                            <Form.Item
                                name="SampleSenderName"
                                label="Numuneyi Teslim Eden"
                                rules={[
                                    {
                                        required: true,
                                        message: "Lütfen bu alanı boş bırakmayın",
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder=""
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLocaleLowerCase()
                                            .indexOf(input.toLocaleLowerCase()) >= 0
                                    }
                                    dropdownRender={(menu) => (
                                        <div>
                                            {menu}
                                            <Divider style={{margin: "4px 0"}}/>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexWrap: "nowrap",
                                                    padding: 8,
                                                }}
                                            >
                                                <Input
                                                    style={{flex: "auto"}}
                                                    ref={(refs) => (this.SampleSenderName = refs)}
                                                />
                                                <a
                                                    style={{
                                                        flex: "none",
                                                        padding: "8px",
                                                        display: "block",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                        this.addItem({
                                                            type: "SampleUserIdAdd",
                                                            value: this.SampleSenderName.state?.value || null,
                                                        })
                                                    }
                                                >
                                                    <PlusOutlined/> Ekle
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                    onChange={this._senderCustomerChange}
                                >
                                    {person.map((item) => {
                                        return (
                                            <Option
                                                key={item.index}
                                                value={item.name}
                                                customerId={item.customer_id}
                                            >
                                                {item.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col md={6}>
                            <Form.Item
                                name="SampleSenderCustomer"
                                label="Numuneyi Teslim Eden Şirket"
                                rules={[
                                    {
                                        required: true,
                                        message: "Lütfen bu alanı boş bırakmayın",
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder=""
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLocaleLowerCase()
                                            .indexOf(input.toLocaleLowerCase()) >= 0
                                    }
                                    dropdownRender={(menu) => (
                                        <div>
                                            {menu}
                                            <Divider style={{margin: "4px 0"}}/>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexWrap: "nowrap",
                                                    padding: 8,
                                                }}
                                            >
                                                <Input
                                                    style={{flex: "auto"}}
                                                    ref={(refs) => (this.SampleSenderCustomer = refs)}
                                                />
                                                <a
                                                    style={{
                                                        flex: "none",
                                                        padding: "8px",
                                                        display: "block",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                        this.addItem({
                                                            type: "SampleUserCustomerIdAdd",
                                                            value:
                                                                this.SampleSenderCustomer.state?.value || null,
                                                        })
                                                    }
                                                >
                                                    <PlusOutlined/> Ekle
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                >
                                    {personCustomer.map((item, index) => {
                                        return (
                                            <Option key={index} value={item.name}>
                                                {item.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                                {/* Numuneyi Alan belliyse Şirketi de bellidir */}
                            </Form.Item>
                        </Col>

                        <Col md={6}>
                            <Row gutter={16}>
                                <Col span={14}>
                                    <Form.Item
                                        name="SampleAcceptanceDate"
                                        label="Numune Kabul Tarih"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Lütfen bu alanı boş bırakmayın",
                                            },
                                        ]}
                                        initialValue={dateTimeMoment()}
                                    >
                                        <DatePicker
                                            style={{width: "100%"}}
                                            placeholder="Tarih Seçiniz"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item
                                        name="SampleAcceptanceHour"
                                        label="Saat"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Lütfen bu alanı boş bırakmayın",
                                            },
                                        ]}
                                        initialValue={dateTimeMoment()}
                                    >
                                        <TimePicker
                                            format={dateTimeFormat}
                                            placeholder="Saat Seçiniz."
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>

                        <Col md={6}>
                            <Form.Item
                                name="SampleCount"
                                label="Numune Adedi"
                                rules={[
                                    {
                                        required: true,
                                        message: "En fazla 20 adet seçebilirsiniz.",
                                        type: "number",
                                        max: 20,
                                        min: 0,
                                    },
                                ]}
                                initialValue={1}
                            >
                                <InputNumber
                                    style={{width: "100%"}}
                                    min={1}
                                    max={20}
                                    onChange={this._sampleAmountChange}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider/>

                    <Row gutter={16}>{SampleAmountItems}</Row>

                    <Divider/>

                    <Row gutter={16}>
                        <Col md={16} style={{maxHeight: "170"}}>
                            <Form.Item
                                style={{maxHeight: "170"}}
                                name="images"
                                label="Görseller"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <div className="clearfix">
                                    <Upload
                                        listType="picture-card"
                                        fileList={fileList}
                                        onPreview={this.handlePreview}
                                        onChange={this.handleChange}
                                        accept="image/*"
                                    >
                                        {fileList.length >= 5 ? null : uploadButton}
                                    </Upload>

                                    <Button
                                        style={{marginBottom: "15px"}}
                                        onClick={() => this.setState({visible: true})}
                                    >
                                        <CameraOutlined/> Çek
                                    </Button>

                                    <Modal
                                        visible={previewVisible}
                                        title={previewTitle}
                                        footer={null}
                                        onCancel={this.handleCancel}
                                    >
                                        <img
                                            alt="example"
                                            style={{width: "100%"}}
                                            src={previewImage}
                                        />
                                    </Modal>
                                </div>
                            </Form.Item>
                        </Col>
                        <Col md={8}>
                            <Form.Item
                                name="SampleProblem"
                                label=""
                                valuePropName="checked"
                                initialValue={SampleProblem || false}
                            >
                                <Checkbox
                                    onChange={(e) =>
                                        this.setState({SampleProblem: e.target.checked})
                                    }
                                >
                                    Sorunlu Numune mi?
                                </Checkbox>
                            </Form.Item>
                            {SampleProblem && (
                                <Form.Item
                                    name="SampleProblemComment"
                                    label="Sorunlu Numunenin Açıklaması"
                                >
                                    <Input/>
                                </Form.Item>
                            )}
                            <Form.Item
                                name="SampleDraft"
                                label=""
                                valuePropName="checked"
                                initialValue={true}
                            >
                                <Checkbox>Taslak Rapor</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className="stepActions">
                        <Space align="center">
                            <Button
                                size="large"
                                style={{margin: "0 8px"}}
                                onClick={this.previous}
                                icon={<ArrowLeftOutlined/>}
                            >
                                Önceki
                            </Button>

                            <Button
                                size="large"
                                type="primary"
                                htmlType="submit"
                                //  onClick={this.next}
                                icon={<ArrowRightOutlined/>}
                            >
                                Sonraki
                            </Button>
                        </Space>
                    </div>
                </Form>
                <Modal
                    title="Fotoğraf Çek"
                    visible={this.state.visible}
                    footer={[]}
                    onCancel={() => this.setState({visible: false})}
                >
                    <WebCam
                        onFinish={(img) =>
                            this.setState({
                                visible: false,
                                fileList: [
                                    ...this.state.fileList,
                                    {
                                        uid: dateTimeMoment().unix(),
                                        name: dateTimeMoment().unix() + ".jpeg",
                                        status: "done",

                                        url: img,
                                    },
                                ],
                            })
                        }
                    />
                </Modal>

                <Modal
                    title="Trend Analizi Uyarısı!"
                    visible={this.state.showTrendAnalyticsModal}
                    onOk={() => this.createTrendAnalytics()}
                    onCancel={() => this.cancelTrendAnalytics()}
                    okText="Trend analizi yap"
                    cancelText="Vazgeç"
                >
                    <p>Numune üzerinde trend analizi yapılabilir. Trend Analizini etkinleştirmek için <strong>Trend
                        analizi yap</strong> butonuna tıklayınız.</p>
                </Modal>
            </>
        );
    }
}

export default Sample;
