// Servis Çağrıları
import {ApiService} from "../Base";
import * as Endpoint from "../../Config/EndpointUrl";
import moment from "moment";

class Sample {
    constructor() {
    }

    /**
     * Sample Add
     * @param {boolean} trendAnalyticsRequested - TrendAnalyticsRequested
     * @param {string} EquipmentHour - EquipmentHour
     * @param {string} OilHour - OilHour
     * @param {string} WorkOrderName - WorkOrder Adı
     * @param {string} WorkOrderCustomerId - WorkOrder Müşteri Id
     * @param {string} WorkOrderProductId - WorkOrder Ürün Id
     * @param {string} WorkOrderSectorId - WorkOrder Sector Id
     * @param {string} WorkOrderOperationId - WorkOrder Operasyon Id
     * @param {string} SampleLocation - Alım Yeri
     * @param {string} SamplePart - Alındığı Yer
     * @param {string} SamplePartAdd - Alındığı Yer -Ek
     * @param {string} SampleDateDelivery - Gönderim Tarihi
     * @param {string} SampleSignetCode -Mühür Bilgileri
     * @param {string} SampleUserId - Numuneyi Alan Kullanıcı
     * @param {string} SampleUserCustomerId - Numuneyi Alan Şirkey
     * @param {string} SampleSenderName -Numuneyi Teslim Eden
     * @param {string} SampleMethod - Numune Alma Metodu
     * @param {string} SampleAcceptanceDate - Numune Kabul Tarihi
     * @param {string} SampleAcceptanceHour - Numune Kabul SAati
     * @param {string} SampleCount - Numune Adedi
     * @param {Array} SampleTests - Testler {type,sample_test_id}
     * @param {array} SampleStock - {type_id,amount,amount_type,lab_id,date_finish,endless_storage,store_id,store_part_id}
     */
    Create = ({
                  trendAnalyticsRequested,
                  EquipmentHour,
                  OilHour,
                  WorkOrderName,
                  WorkOrderCustomerId,
                  WorkOrderProductId,
                  WorkOrderSectorId,
                  WorkOrderOperationId,
                  SampleLocation,
                  SamplePart,
                  get_detail,
                  SamplePartAdd,
                  SampleDateDelivery,
                  SampleDateDeliveryHour,
                  SampleProblem = false,
                  SampleDraft = false,
                  SampleUserId,
                  SampleUserCustomerId,
                  SampleSenderName,
                  SampleMethod,
                  SampleAcceptanceDate,
                  SampleAcceptanceHour,
                  SampleCount,
                  SampleTests,
                  SampleStock,
                  images,
                  SampleSenderCustomer,
                  SampleTestCount,
                  WorkOrderTypeId,
                  SampleProblemComment,
                  SampleMultiple,
              }) => {
        return new Promise(async (resolve, reject) => {
            try {
                let sample_stock = [];
                for (const key in SampleStock) {
                    if (SampleStock.hasOwnProperty(key)) {
                        const element = SampleStock[key];
                        delete element.endless_storage;
                        delete element.endless_storage_text;
                        sample_stock.push(element);
                    }
                }
                const requestParams = {
                    work_order: {
                        customer_id: WorkOrderCustomerId,
                        product_id: WorkOrderProductId,
                        sector_id: WorkOrderSectorId,
                        operation_id: WorkOrderOperationId,
                        draft_report: SampleDraft == true ? 1 : 0,
                        work_order_type_id: WorkOrderTypeId,
                        get_detail: get_detail,
                    },
                    sample: {
                        trend_analytics_requested: trendAnalyticsRequested,
                        equipment_hours: EquipmentHour,
                        oil_hours: OilHour,
                        get_location: SampleLocation,
                        get_part: WorkOrderName,
                        get_part_cat: SamplePart,
                        get_detail: get_detail,
                        date_delivery: SampleDateDelivery
                            ? moment(SampleDateDelivery).unix()
                            : null,
                        date_delivery_hour: SampleDateDeliveryHour
                            ? moment(SampleDateDeliveryHour).unix()
                            : null,
                        get_user_id: SampleUserId,
                        get_user_customer_id: SampleUserCustomerId,
                        get_sender_name: SampleSenderName,
                        get_sender_customer: SampleSenderCustomer,
                        get_method: SampleMethod,
                        get_acceptance_date: moment(SampleAcceptanceDate).unix(),
                        get_acceptance_hour: moment(SampleAcceptanceHour).unix(),
                        get_sample_piece: SampleCount,
                        sample_test_count: SampleTestCount,
                        tankbytank: SampleTestCount,
                        sample_tests: SampleTests,
                        problem: SampleProblem == true ? 1 : 0,
                        comment_problem: SampleProblemComment,
                    },
                    sample_stock: sample_stock,
                };

                const requestForm = new FormData();
                requestForm.append(
                    "work_order",
                    JSON.stringify(requestParams.work_order)
                );
                requestForm.append("sample", JSON.stringify(requestParams.sample));
                requestForm.append(
                    "sample_stock",
                    JSON.stringify(requestParams.sample_stock)
                );
                for (const key in images) {
                    if (images.hasOwnProperty(key)) {
                        const element = images[key];
                        requestForm.append("item_images[]", element.image, element.name);
                    }
                }
                const response = await ApiService(
                    "post",
                    Endpoint.Sample.create,
                    requestForm,
                    true
                );
                let result = {
                    status: false,
                    message: "Bir hata meydana geldi",
                };
                if (response.status) {
                    result = {
                        status: true,
                        message: response.message,
                        data: response.reply,
                    };
                } else {
                    result.message = response.message;
                }
                resolve(result);
            } catch (error) {
                console.log("Sample-Create-error", {error});
                reject(error);
            }
        });
    };
    /**
     * Sample Update
     * @param {boolean} trendAnalyticsRequested - TrendAnalyticsRequested
     * @param {string} EquipmentHour - EquipmentHour
     * @param {string} OilHour - OilHour
     * @param {string} WorkOrderName - WorkOrder Adı
     * @param {string} WorkOrderCustomerId - WorkOrder Müşteri Id
     * @param {string} WorkOrderProductId - WorkOrder Ürün Id
     * @param {string} WorkOrderSectorId - WorkOrder Sector Id
     * @param {string} WorkOrderOperationId - WorkOrder Operasyon Id
     * @param {string} SampleId - Sample Id
     * @param {string} SampleLocation - Alım Yeri
     * @param {string} SamplePart - Alındığı Yer
     * @param {string} SamplePartAdd - Alındığı Yer -Ek
     * @param {string} SampleDateDelivery - Gönderim Tarihi
     * @param {string} SampleSignetCode -Mühür Bilgileri
     * @param {string} SampleUserId - Numuneyi Alan Kullanıcı
     * @param {string} SampleUserCustomerId - Numuneyi Alan Şirkey
     * @param {string} SampleSenderName -Numuneyi Teslim Eden
     * @param {string} SampleMethod - Numune Alma Metodu
     * @param {string} SampleAcceptanceDate - Numune Kabul Tarihi
     * @param {string} SampleAcceptanceHour - Numune Kabul SAati
     * @param {string} SampleCount - Numune Adedi
     * @param {Array} SampleTests - Testler {type,sample_test_id}
     * @param {array} SampleStock - {type_id,amount,amount_type,lab_id,date_finish,endless_storage,store_id,store_part_id}
     */
    Update = ({
                  trendAnalyticsRequested,
                  EquipmentHour,
                  OilHour,
                  WorkOrderName,
                  WorkOrderCustomerId,
                  WorkOrderProductId,
                  WorkOrderSectorId,
                  WorkOrderOperationId,
                  SampleId,
                  SampleLocation,
                  SamplePart,
                  get_detail,
                  SamplePartAdd,
                  SampleDateDelivery,
                  SampleDateDeliveryHour,
                  SampleProblem = false,
                  SampleDraft = false,
                  SampleUserId,
                  SampleUserCustomerId,
                  SampleSenderName,
                  SampleMethod,
                  SampleAcceptanceDate,
                  SampleAcceptanceHour,
                  SampleCount,
                  SampleTests,
                  SampleStock,
                  images,
                  SampleSenderCustomer,
                  SampleTestCount,
                  SampleProblemComment,
                  SampleMultiple,
                  WorkOrderTypeId,
              }) => {
        return new Promise(async (resolve, reject) => {
            try {
                let sample_stock = [];
                for (const key in SampleStock) {
                    if (SampleStock.hasOwnProperty(key)) {
                        const element = SampleStock[key];
                        delete element.endless_storage;
                        delete element.endless_storage_text;
                        sample_stock.push(element);
                    }
                }

                const requestParams = {
                    work_order: {
                        customer_id: WorkOrderCustomerId,
                        product_id: WorkOrderProductId,
                        sector_id: WorkOrderSectorId,
                        operation_id: WorkOrderOperationId,
                        draft_report: SampleDraft == true ? 1 : 0,
                        work_order_type_id: WorkOrderTypeId,
                        get_detail: get_detail,
                    },
                    sample: {
                        trend_analytics_requested: trendAnalyticsRequested,
                        equipment_hours: EquipmentHour,
                        oil_hours: OilHour,
                        sample_id: SampleId,
                        get_location: SampleLocation,
                        get_part: WorkOrderName,
                        get_part_cat: SamplePart,
                        get_detail: get_detail,

                        date_delivery: SampleDateDelivery
                            ? moment(SampleDateDelivery).unix()
                            : null,
                        date_delivery_hour: SampleDateDeliveryHour
                            ? moment(SampleDateDeliveryHour).unix()
                            : null,
                        get_user_id: SampleUserId,
                        get_user_customer_id: SampleUserCustomerId,
                        get_sender_name: SampleSenderName,
                        get_sender_customer: SampleSenderCustomer,
                        get_method: SampleMethod,
                        get_acceptance_date: moment(SampleAcceptanceDate).unix(),
                        get_acceptance_hour: moment(SampleAcceptanceHour).unix(),
                        get_sample_piece: SampleCount,
                        sample_tests: SampleTests,
                        problem: SampleProblem == true ? 1 : 0,
                        comment_problem: SampleProblemComment,
                        sample_test_count: !!SampleMultiple ? 0 : SampleTestCount,
                        tankbytank: !!SampleMultiple ? 0 : SampleTestCount,
                    },
                    sample_stock: sample_stock,
                };

                const requestForm = new FormData();
                requestForm.append(
                    "work_order",
                    JSON.stringify(requestParams.work_order)
                );
                requestForm.append("sample", JSON.stringify(requestParams.sample));
                requestForm.append(
                    "sample_stock",
                    JSON.stringify(requestParams.sample_stock)
                );
                for (const key in images) {
                    if (images.hasOwnProperty(key)) {
                        const element = images[key];
                        requestForm.append("item_images[]", element.image, element.name);
                    }
                }

                const response = await ApiService(
                    "post",
                    Endpoint.Sample.update,
                    requestForm,
                    true
                );
                let result = {
                    status: false,
                    message: "Bir hata meydana geldi",
                };
                if (response.status) {
                    result = {
                        status: true,
                        message: response.message,
                        data: response.reply,
                    };
                } else {
                    result.message = response.message;
                }
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    };

    /**
     * Sample List
     * @param {number} limit
     * @param {number} page
     * @param {number} startDate
     * @param {number} endDate
     */
    SelectAll = (type = 0, limit = 0, page = 0, startDate = 0, endDate = 0) => {
        return new Promise(async (resolve, reject) => {
            try {
                const endpoints = `${Endpoint.Sample.getAll}/${limit}/${page}/${startDate}/${endDate}/${type}`;
                const response = await ApiService("get", endpoints);
                let result = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                    response: {
                        details: {},
                        data: [],
                    },
                };

                if (response.status) {
                    result = {
                        status: true,
                        message: response.message,
                        response: {
                            details: response.reply.pagination,
                            data: response.reply.posts,
                        },
                    };
                } else {
                    result.message = response.message;
                }
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    };
    /**
     * Sample Details
     * @param {string} sampleId
     */
    Select = (sampleId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const endpoints = `${Endpoint.Sample.getOne}/${sampleId}`;
                const response = await ApiService("get", endpoints);
                let result = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                    response: {
                        details: {},
                        data: [],
                    },
                };
                if (response.status) {
                    result = {
                        status: true,
                        message: response.message,
                        data: response.reply,
                    };
                } else {
                    result.message = response.message;
                }
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    };
    /**
     * Work Order Listeleme
     * @param {string} customerId
     * @param {string} sectorId
     * @param {string} productId
     * @param {string} operationId
     * @param {string} name
     */
    workOrderByCustomer = (
        customerId = 0,
        sectorId = 0,
        productId = 0,
        operationId = 0,
        name = 0
    ) => {
        return new Promise(async (resolve, reject) => {
            try {
                const endpoints = `${
                    Endpoint.Sample.workOrderByCustomer
                }/${customerId}/${sectorId}/${productId}/${operationId}/${encodeURIComponent(
                    name
                )}`;
                const response = await ApiService("get", endpoints);
                let result = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                    response: {
                        details: {},
                        data: [],
                    },
                };

                if (response.status) {
                    result = {
                        status: true,
                        message: response.message,
                        data: response.reply,
                    };
                } else {
                    result.message = response.message;
                }
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    };

    /**
     * Alım Yeri Listeleme
     */
    getLocation = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const endpoints = `${Endpoint.Sample.getLocation}`;
                const response = await ApiService("get", endpoints);
                let result = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                    response: {
                        details: {},
                        data: [],
                    },
                };
                if (response.status) {
                    result = {
                        status: true,
                        message: response.message,
                        data: response.reply,
                    };
                } else {
                    result.message = response.message;
                }
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    };
    /**
     * Alım Yeri Listeleme Detay
     */
    getLocationOne = (locationId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const endpoints = `${Endpoint.Sample.getLocationOne}/${locationId}`;
                const response = await ApiService("get", endpoints);
                let result = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                    response: {},
                };
                if (response.status) {
                    result = {
                        status: true,
                        message: response.message,
                        data: response.reply[0],
                    };
                } else {
                    result.message = response.message;
                }
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    };
    /**
     * Alındığı Yer  Listeleme
     */
    getPart = (sectorId = 0) => {
        return new Promise(async (resolve, reject) => {
            try {
                const endpoints = `${Endpoint.Sample.getPart}/${sectorId}`;
                const response = await ApiService("get", endpoints);
                let result = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                    response: {
                        details: {},
                        data: [],
                    },
                };
                if (response.status) {
                    result = {
                        status: true,
                        message: response.message,
                        data: response.reply,
                    };
                } else {
                    result.message = response.message;
                }
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    };
    /**
     * Alındığı Yer  Listeleme
     */
    getPartCat = (sectorId = 0) => {
        return new Promise(async (resolve, reject) => {
            try {
                const endpoints = `${Endpoint.Sample.getPartCat}/${sectorId}`;
                const response = await ApiService("get", endpoints);
                let result = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                    response: {
                        details: {},
                        data: [],
                    },
                };
                if (response.status) {
                    result = {
                        status: true,
                        message: response.message,
                        data: response.reply,
                    };
                } else {
                    result.message = response.message;
                }
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    };

    /**
     * Sotck All
     * @param {number} limit
     * @param {number} page
     * @param {number} startDate
     * @param {number} endDate
     */
    stockSelect = (limit = 0, page = 0, startDate = 0, endDate = 0) => {
        return new Promise(async (resolve, reject) => {
            try {
                const endpoints = `${Endpoint.Sample.getStock}/${limit}/${page}/${startDate}/${endDate}`;
                const response = await ApiService("get", endpoints);
                let result = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                    response: {
                        details: {},
                        data: [],
                    },
                };
                if (response.status) {
                    result = {
                        status: true,
                        message: response.message,
                        response: {
                            details: response.reply.pagination,
                            data: response.reply.posts,
                        },
                    };
                } else {
                    result.message = response.message;
                }
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    };
    /**
     * Stock Update
     * @param {string} stockId
     * @param {string} storeId
     * @param {string} shelfId
     * @param {string} storeTime
     */
    stockUpdate = ({stockId, storeId, shelfId, storeTime}) => {
        return new Promise(async (resolve, reject) => {
            try {
                const requestParams = {
                    stock_id: stockId,
                    store_id: storeId,
                    store_part_id: shelfId,
                    date_finish: storeTime,
                };
                const response = await ApiService(
                    "put",
                    Endpoint.Sample.updateStock,
                    requestParams
                );
                let result = {
                    status: false,
                    message: "Bir hata meydana geldi",
                };
                if (response.status) {
                    result = {
                        status: true,
                        message: response.message,
                    };
                } else {
                    result.message = response.message;
                }
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    };
    /**
     * Method All

     */
    methodSelect = (type = 1) => {
        return new Promise(async (resolve, reject) => {
            try {
                const endpoints = `${Endpoint.Sample.getMethod}/${type}`;
                const response = await ApiService("get", endpoints);
                let result = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                    response: {
                        details: {},
                        data: [],
                    },
                };
                if (response.status) {
                    result = {
                        status: true,
                        message: response.message,
                        data: response.reply,
                    };
                } else {
                    result.message = response.message;
                }
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    };
    /**
     * Qr Kod İle Baskı Alma
     * @param {string} sampleId - Numune Numarası
     */
    SelectQrCode = (sampleId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const endpoints = `${Endpoint.Sample.selectQr}/${sampleId}`;
                const response = await ApiService("get", endpoints);
                let result = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                    data: [],
                };
                console.log("responseQr", response);
                if (response.status) {
                    result = {
                        status: true,
                        message: response.message,
                        data: response.reply,
                    };
                } else {
                    result.message = response.message;
                }
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    };

    /**
     * Numuneyi Alan Personel Listesi
     */
    SelectPerson = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await ApiService("get", Endpoint.Sample.selectPerson);
                let result = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                    data: [],
                };

                if (response.status) {
                    result = {
                        status: true,
                        message: response.message,
                        data: response.reply,
                    };
                } else {
                    result.message = response.message;
                }
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    };
    /**
     * Numuneyi Alan Personel Listesi Ekleme
     */
    PersonCreate = ({nameSurname, customerId, regionId}) => {
        return new Promise(async (resolve, reject) => {
            try {
                const requestParams = {
                    name: nameSurname,
                    customerId: customerId,
                    regionId: regionId,
                };
                const response = await ApiService(
                    "post",
                    Endpoint.Sample.personCreate,
                    requestParams
                );
                let result = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                    data: [],
                };

                if (response.status) {
                    result = {
                        status: true,
                        message: response.message,
                        data: response.reply,
                    };
                } else {
                    result.message = response.message;
                }
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    };
    /**
     * Numuneyi Alan Personel Listesi
     */
    PersonUpdate = ({id, nameSurname, customerId, regionId}) => {
        return new Promise(async (resolve, reject) => {
            try {
                const requestParams = {
                    personId: id,
                    name: nameSurname,
                    customerId: customerId,
                    regionId: regionId,
                };
                const response = await ApiService(
                    "put",
                    Endpoint.Sample.personUpdate,
                    requestParams
                );
                let result = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                    data: [],
                };

                if (response.status) {
                    result = {
                        status: true,
                        message: response.message,
                        data: response.reply,
                    };
                } else {
                    result.message = response.message;
                }
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    };
    /**
     *Numuneyi Alan Personel  Silme İşlemi
     * @param {string} getLocationId
     */
    PersonDelete = (personId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const requestParams = {
                    personId,
                };
                const response = await ApiService(
                    "delete",
                    Endpoint.Sample.personDelete,
                    requestParams
                );
                let result = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                };
                if (response.status) {
                    result = {
                        status: true,
                        message: response.message,
                    };
                } else {
                    result.message = response.message;
                }
                resolve(result);
            } catch (error) {
            }
        });
    };
    /**
     * Numuneyi Alan Personel Listesi
     */
    SelectCustomer = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await ApiService(
                    "get",
                    Endpoint.Sample.selectCustomer
                );
                let result = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                    data: [],
                };

                if (response.status) {
                    result = {
                        status: true,
                        message: response.message,
                        data: response.reply,
                    };
                } else {
                    result.message = response.message;
                }
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    };
    /**
     * Testleri Listeleme
     */
    getTests = (sampleId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `${Endpoint.Sample.getTests}/${sampleId}`;
                const response = await ApiService("get", url);
                let result = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                    details: {},
                    data: [],
                    users: [],
                    sampleUsers: [],
                    accredited_options: [],
                    info: "",
                    reportInfo: {
                        reportTypeId: undefined,
                        reportDesc: "",
                        standadId: undefined,
                        signUserId: 0,
                        signCUserId: 0,
                    },
                    tankbytankList: [],
                };
                if (result.status) {
                    for (const key in response.reply.sample) {
                        if (response.reply.sample.hasOwnProperty(key)) {
                            const element = response.reply.sample[key];
                            if (element.length == 0) delete response.reply.sample[key];
                        }
                    }
                }

                if (response.status) {
                    let info = "";
                    if (response?.reply?.test_pack_info?.content) {
                        info = `${response.reply.test_pack_info.content} \n ${response.reply.test_pack_info.content_en}`;
                    }
                    result = {
                        status: true,
                        message: response.message,
                        details: response.reply.sampleInfo,
                        users: response.reply.labUser,
                        sampleUsers: response.reply.sampleUser,
                        data: response.reply.sample,
                        acredite: response.reply.accredited_options,
                        photo: response.reply.sample_photo,
                        info,
                        reportInfo: response.reply.report_info,
                        tankbytankList: response.reply.sample_test_tank,
                    };
                } else {
                    result.message = response.message;
                }

                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    };
    /**
     * Test Set Etme
     */
    setTests = ({
                    sampleId,
                    result = "",
                    testUserId = "",
                    testId = "",
                    packId = "",
                    refMin = "",
                    refMax = "",
                    urealResult = "",
                    operator = "=",
                    ref_min_operator = "=",
                    ref_max_operator = "=",
                    sampleTestId,
                }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const requestParams = {
                    sampleId,
                    result,
                    testUserId,
                    testId,
                    packId,
                    refMax,
                    refMin,
                    urealResult,
                    operator,
                    ref_min_operator,
                    ref_max_operator,
                    sampleTestId,
                };

                const response = await ApiService(
                    "post",
                    Endpoint.Sample.setTests,
                    requestParams
                );
                let responseResult = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                };

                if (response.status) {
                    responseResult = {
                        status: true,
                        message: response.message,
                    };
                } else {
                    responseResult.message = response.message;
                }

                resolve(responseResult);
            } catch (error) {
                reject(error);
            }
        });
    };

    /**
     * Revizeye Gönderme
     * @param {string} sampleId - Test Id

     * @param {string} description - Açıklama
     * @param {array} sampleTestIds - Test Listesi
     * @param {number} type -  type 1 ise standart revize 2 ise düzenleme isteği
     */
    sendRevised = ({sampleId, description, sampleTestIds, type}) => {
        return new Promise(async (resolve, reject) => {
            try {
                const requestParams = {
                    sampleId,

                    description,
                    sampleTestIds,
                    type,
                };
                const response = await ApiService(
                    "post",
                    Endpoint.Sample.sendRevised,
                    requestParams
                );
                let responseResult = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                };

                if (response.status) {
                    responseResult = {
                        status: true,
                        message: response.message,
                    };
                } else {
                    responseResult.message = response.message;
                }

                resolve(responseResult);
            } catch (error) {
                reject(error);
            }
        });
    };
    /**
     * Rapor Oluşturma
     * @param {string} sampleId - Test Id
     * @param {string} description - Açıklama
     * @param {string} description_en - Açıklama EN
     * @param {string} report_type_id - Raporlama Tipi
     * @param {string} light - Rapor Işık
     * @param {array} descriptions
     * @param {array} sampleCommentRevised
     * @param {array} sampleCommentRevisedEn
     *
     */
    sendReport = ({
                      sampleId,
                      description,
                      description_en,
                      report_type_id,
                      descriptions,
                      standart_id,
                      images,
                      reportSignatureUserId,
                      reportCuSignatureUserId,
                      reportSignatureType,
                      light,
                      sampleCommentRevised = "",
                      sampleCommentRevisedEn = "",
                      sendmailreport = false,
                  }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const requestParams = {
                    sampleId,

                    description,
                    description_en,
                    report_type_id,
                    descriptions,
                    reference_standard_id: standart_id,
                };
                const requestForm = new FormData();
                requestForm.append("sampleId", sampleId);
                requestForm.append("description", description);
                requestForm.append("description_en", description_en);
                requestForm.append("report_type_id", report_type_id);
                requestForm.append("descriptions", JSON.stringify(descriptions));
                requestForm.append("reference_standard_id", standart_id);
                requestForm.append("signUserId", reportSignatureUserId);
                requestForm.append("signCUserId", reportCuSignatureUserId);
                requestForm.append("signatureType", reportSignatureType);
                requestForm.append("light", light);
                requestForm.append("sampleCommentRevised", sampleCommentRevised);
                requestForm.append("sampleCommentRevised_en", sampleCommentRevisedEn);
                requestForm.append("sendmailreport", sendmailreport);

                for (const key in images) {
                    if (images.hasOwnProperty(key)) {
                        const element = images[key];
                        requestForm.append("item_images[]", element.image, element.name);
                    }
                }
                const response = await ApiService(
                    "post",
                    Endpoint.Sample.sendReport,
                    requestForm,
                    true
                );
                let responseResult = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                    fileName: null,
                };

                if (response.status) {
                    responseResult = {
                        status: true,
                        message: response.message,
                        fileName: response.reply.pdf_name,
                    };
                } else {
                    responseResult.message = response.message;
                }

                resolve(responseResult);
            } catch (error) {
                reject(error);
            }
        });
    };

    /**
     * Sample Report Desc
     */
    showReportDesc = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await ApiService("get", Endpoint.Sample.reportDesc);

                let responseResult = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                    data: [],
                };

                if (response.status) {
                    responseResult = {
                        status: true,
                        message: response.message,
                        data: response.reply,
                    };
                } else {
                    responseResult.message = response.message;
                }

                resolve(responseResult);
            } catch (error) {
                reject(error);
            }
        });
    };
    /**
     * Sample Report Type
     */
    showReportType = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await ApiService("get", Endpoint.Sample.reportType);

                let responseResult = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                    data: [],
                };

                if (response.status) {
                    responseResult = {
                        status: true,
                        message: response.message,
                        data: response.reply,
                    };
                } else {
                    responseResult.message = response.message;
                }

                resolve(responseResult);
            } catch (error) {
                reject(error);
            }
        });
    };

    /**
     * Testi Status Değiştirme
     * @param {string} sampleId - Test Id
     * @param {string} status - 'start' || 'finish'

     */
    changeStatusTest = (sampleId, status = "start") => {
        return new Promise(async (resolve, reject) => {
            try {
                const requestParams = {
                    sampleId,
                    status,
                };
                const response = await ApiService(
                    "post",
                    Endpoint.Sample.startTest,
                    requestParams
                );
                let responseResult = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                };

                if (response.status) {
                    responseResult = {
                        status: true,
                        message: response.message,
                    };
                } else {
                    responseResult.message = response.message;
                }

                resolve(responseResult);
            } catch (error) {
                reject(error);
            }
        });
    };

    /**
     * Pdf İndirme
     * @param {string} pdfName - Dosya İsmi


     */
    showReport = (pdfName) => {
        return new Promise(async (resolve, reject) => {
            try {
                const requestUrl = Endpoint.Sample.getPdf + "/" + pdfName;
                const response = await ApiService("get", requestUrl);
                console.log("response", response);
                let responseResult = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                };

                if (response.status) {
                    responseResult = {
                        status: true,
                        message: response.message,
                    };
                } else {
                    responseResult.message = response.message;
                }

                resolve(responseResult);
            } catch (error) {
                reject(error);
            }
        });
    };

    /**
     * Rapor açıklaması girme
     * @param {string} name
     * @param {string} name_en
     * @param {string} desc
     * @param {string} desc_en
     */
    reportDescCreates = ({name, name_en, desc, desc_en}) => {
        return new Promise(async (resolve, reject) => {
            try {
                const requestParams = {
                    name,
                    name_en,
                    desc,
                    desc_en,
                };

                const response = await ApiService(
                    "post",
                    Endpoint.Sample.reportDescCreate,
                    requestParams
                );
                let responseResult = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                };

                if (response.status) {
                    responseResult = {
                        status: true,
                        message: response.message,
                    };
                } else {
                    responseResult.message = response.message;
                }

                resolve(responseResult);
            } catch (error) {
                reject(error);
            }
        });
    };

    /**
     *
     * @param {string} reportNo - Numune Rapor Numarası
     */
    remove = (reportNo) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await ApiService("post", Endpoint.Sample.remove, {
                    reportNo,
                });

                let responseResult = {
                    status: response.status,
                    message: response.message,
                };

                resolve(responseResult);
            } catch (error) {
                reject(error);
            }
        });
    };
    /**
     * Terminal Bilgisi Ekleme
     * @param {string} id
     * @param {string} name
     * @param {string} name_en

     */
    terminalCreate = ({name, name_en, mail = []}) => {
        return new Promise(async (resolve, reject) => {
            try {
                const requestParams = {
                    name,
                    nameEn: name_en,
                    mail,
                };
                const response = await ApiService(
                    "post",
                    Endpoint.Sample.terminalCreate,
                    requestParams
                );
                let responseResult = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                };

                if (response.status) {
                    responseResult = {
                        status: true,
                        message: response.message,
                    };
                } else {
                    responseResult.message = response.message;
                }

                resolve(responseResult);
            } catch (error) {
                reject(error);
            }
        });
    };
    /**
     * Terminal Bilgisini Güncelleme
     * @param {string} id
     * @param {string} name
     * @param {string} name_en

     */
    terminalUpdate = ({name, name_en, id, mail = []}) => {
        return new Promise(async (resolve, reject) => {
            try {
                const requestParams = {
                    name,
                    nameEn: name_en,
                    getLocationId: id,
                    mail,
                };
                const response = await ApiService(
                    "put",
                    Endpoint.Sample.terminalUpdate,
                    requestParams
                );
                let responseResult = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                };

                if (response.status) {
                    responseResult = {
                        status: true,
                        message: response.message,
                    };
                } else {
                    responseResult.message = response.message;
                }

                resolve(responseResult);
            } catch (error) {
                reject(error);
            }
        });
    };
    /**
     *Terminal  Bilgisini  Silme İşlemi
     * @param {string} getLocationId
     */
    terminalDelete = (getLocationId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const requestParams = {
                    getLocationId,
                };
                const response = await ApiService(
                    "delete",
                    Endpoint.Sample.terminalDelete,
                    requestParams
                );
                let result = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                };
                if (response.status) {
                    result = {
                        status: true,
                        message: response.message,
                    };
                } else {
                    result.message = response.message;
                }
                resolve(result);
            } catch (error) {
            }
        });
    };

    /**
     * Numune Alma Method Ekle

     * @param {string} name
     * @param {string} name_en

     */
    sampleMethodCreate = ({name, name_en}) => {
        return new Promise(async (resolve, reject) => {
            try {
                const requestParams = {
                    name,
                    nameEn: name_en,
                };
                const response = await ApiService(
                    "post",
                    Endpoint.Sample.sampleMethodCreate,
                    requestParams
                );
                let responseResult = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                };

                if (response.status) {
                    responseResult = {
                        status: true,
                        message: response.message,
                    };
                } else {
                    responseResult.message = response.message;
                }

                resolve(responseResult);
            } catch (error) {
                reject(error);
            }
        });
    };

    /**
     * Numune Alma Method Güncelleme
     * @param {string} id
     * @param {string} name
     * @param {string} name_en

     */
    sampleMethodUpdate = ({name, name_en, id}) => {
        return new Promise(async (resolve, reject) => {
            try {
                const requestParams = {
                    name,
                    nameEn: name_en,
                    getMethodId: id,
                };
                const response = await ApiService(
                    "put",
                    Endpoint.Sample.sampleMethodUpdate,
                    requestParams
                );
                let responseResult = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                };

                if (response.status) {
                    responseResult = {
                        status: true,
                        message: response.message,
                    };
                } else {
                    responseResult.message = response.message;
                }

                resolve(responseResult);
            } catch (error) {
                reject(error);
            }
        });
    };

    /**
     * Numune Alma Method Silme İşlemi
     * @param {string} getMethodId
     */
    sampleMethodDelete = (getMethodId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const requestParams = {
                    getMethodId,
                };
                const response = await ApiService(
                    "delete",
                    Endpoint.Sample.sampleMethodDelete,
                    requestParams
                );
                let result = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                };
                if (response.status) {
                    result = {
                        status: true,
                        message: response.message,
                    };
                } else {
                    result.message = response.message;
                }
                resolve(result);
            } catch (error) {
            }
        });
    };

    /**
     * Numunenin Alındığı Yer Bilgisini Ekle

     * @param {string} name
     * @param {string} name_en
     * @param {string} sector_id

     */
    sampleAtCreate = ({name, name_en, sector_id}) => {
        return new Promise(async (resolve, reject) => {
            try {
                const requestParams = {
                    name,
                    nameEn: name_en,
                    sectorId: sector_id,
                };
                const response = await ApiService(
                    "post",
                    Endpoint.Sample.sampleAtCreate,
                    requestParams
                );
                let responseResult = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                };

                if (response.status) {
                    responseResult = {
                        status: true,
                        message: response.message,
                    };
                } else {
                    responseResult.message = response.message;
                }

                resolve(responseResult);
            } catch (error) {
                reject(error);
            }
        });
    };
    /**
     * Numunenin Alındığı Yer Bilgisini Güncelleme
     * @param {string} id
     * @param {string} name
     * @param {string} name_en
     * @param {string} sector_id

     */
    sampleAtUpdate = ({name, name_en, sector_id, id}) => {
        return new Promise(async (resolve, reject) => {
            try {
                const requestParams = {
                    name,
                    nameEn: name_en,
                    sectorId: sector_id,
                    getPartId: id,
                };
                const response = await ApiService(
                    "put",
                    Endpoint.Sample.sampleAtUpdate,
                    requestParams
                );
                let responseResult = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                };

                if (response.status) {
                    responseResult = {
                        status: true,
                        message: response.message,
                    };
                } else {
                    responseResult.message = response.message;
                }

                resolve(responseResult);
            } catch (error) {
                reject(error);
            }
        });
    };
    /**
     * Numunenin Alındığı Yer Bilgisini  Silme İşlemi
     * @param {string} getPartId
     */
    sampleAtDelete = (getPartId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const requestParams = {
                    getPartId,
                };
                const response = await ApiService(
                    "delete",
                    Endpoint.Sample.sampleAtDelete,
                    requestParams
                );
                let result = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                };
                if (response.status) {
                    result = {
                        status: true,
                        message: response.message,
                    };
                } else {
                    result.message = response.message;
                }
                resolve(result);
            } catch (error) {
            }
        });
    };
    /**
     *  Alım Yeri Kategori Bilgisini Ekle

     * @param {string} name
     * @param {string} name_en
     * @param {string} sector_id

     */
    sampleAtCategoryCreate = ({name, name_en, sector_id}) => {
        return new Promise(async (resolve, reject) => {
            try {
                const requestParams = {
                    name,
                    nameEn: name_en,
                    sectorId: sector_id
                };
                const response = await ApiService(
                    "post",
                    Endpoint.Sample.sampleAtCategoryCreate,
                    requestParams
                );
                let responseResult = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                };

                if (response.status) {
                    responseResult = {
                        status: true,
                        message: response.message,
                    };
                } else {
                    responseResult.message = response.message;
                }

                resolve(responseResult);
            } catch (error) {
                reject(error);
            }
        });
    };
    /**
     *  Alım Yeri Kategori Bilgisini Güncelleme
     * @param {string} id
     * @param {string} name
     * @param {string} name_en
     * @param {string} sector_id

     */
    sampleAtCategoryUpdate = ({name, name_en, sector_id, id}) => {
        return new Promise(async (resolve, reject) => {
            try {
                const requestParams = {
                    name,
                    nameEn: name_en,
                    sectorId: sector_id,
                    getPartCatId: id
                };
                const response = await ApiService(
                    "put",
                    Endpoint.Sample.sampleAtCategoryUpdate,
                    requestParams
                );
                let responseResult = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                };

                if (response.status) {
                    responseResult = {
                        status: true,
                        message: response.message,
                    };
                } else {
                    responseResult.message = response.message;
                }

                resolve(responseResult);
            } catch (error) {
                reject(error);
            }
        });
    };
    /**
     *  Alım Yeri Kategori Bilgisini  Silme
     * @param {string} getLocationId
     */
    sampleAtCategoryDelete = (getPartCatId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const requestParams = {
                    getPartCatId,
                };
                const response = await ApiService(
                    "delete",
                    Endpoint.Sample.sampleAtCategoryDelete,
                    requestParams
                );
                let result = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                };
                if (response.status) {
                    result = {
                        status: true,
                        message: response.message,
                    };
                } else {
                    result.message = response.message;
                }
                resolve(result);
            } catch (error) {
            }
        });
    };

    /**
     * Numune Rapor Açıklaması Listeleme
     */
    getReportDesc = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const endpoints = `${Endpoint.Sample.reportDescList}`;
                const response = await ApiService("get", endpoints);
                console.log("-getReportDescresponse", response);
                let result = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                    response: {
                        details: {},
                        data: [],
                    },
                };
                if (response.status) {
                    result = {
                        status: true,
                        message: response.message,
                        data: response.reply,
                    };
                } else {
                    result.message = response.message;
                }
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    };
    reportDescCreate = ({
                            reportTitle,
                            reportTitleEn,
                            reportDesc,
                            reportDescEn,
                        }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const requestParams = {
                    name: reportTitle,
                    name_en: reportTitleEn,
                    desc: reportDesc,
                    desc_en: reportDescEn,
                };
                const response = await ApiService(
                    "post",
                    Endpoint.Sample.reportDescCreate,
                    requestParams
                );
                let responseResult = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                };

                if (response.status) {
                    responseResult = {
                        status: true,
                        message: response.message,
                    };
                } else {
                    responseResult.message = response.message;
                }

                resolve(responseResult);
            } catch (error) {
                reject(error);
            }
        });
    };
    /**
     *  Alım Yeri Kategori Bilgisini Güncelleme
     * @param {string} id
     * @param {string} name
     * @param {string} name_en

     */
    reportDescUpdate = ({
                            reportDescId,
                            reportTitle,
                            reportTitleEn,
                            reportDesc,
                            reportDescEn,
                        }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const requestParams = {
                    report_desc_id: reportDescId,
                    name: reportTitle,
                    name_en: reportTitleEn,
                    desc: reportDesc,
                    desc_en: reportDescEn,
                };
                const response = await ApiService(
                    "put",
                    Endpoint.Sample.reportDescUpdate,
                    requestParams
                );
                let responseResult = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                };

                if (response.status) {
                    responseResult = {
                        status: true,
                        message: response.message,
                    };
                } else {
                    responseResult.message = response.message;
                }

                resolve(responseResult);
            } catch (error) {
                reject(error);
            }
        });
    };
    /**
     *  Alım Yeri Kategori Bilgisini  Silme
     * @param {string} getLocationId
     */
    reportDescDelete = (reportDescId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const requestParams = {report_desc_id: reportDescId};
                const response = await ApiService(
                    "delete",
                    Endpoint.Sample.reportDescDelete,
                    requestParams
                );
                let result = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                };
                if (response.status) {
                    result = {
                        status: true,
                        message: response.message,
                    };
                } else {
                    result.message = response.message;
                }
                resolve(result);
            } catch (error) {
            }
        });
    };

    /**
     * Tank Bilgisi güncelleme
     * @param {array} sample_test_tank {id,group_content}


     */
    tankbytankUpdate = (sample_test_tank) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await ApiService(
                    "put",
                    Endpoint.Sample.tankbytankupdate,
                    {sample_test_tank}
                );
                let responseResult = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                };

                if (response.status) {
                    responseResult = {
                        status: true,
                        message: response.message,
                    };
                } else {
                    responseResult.message = response.message;
                }

                resolve(responseResult);
            } catch (error) {
                reject(error);
            }
        });
    };
    /**
     *  Numuneyi alan Şirket listesi

     */
    getPersonCustomer = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const endpoints = `${Endpoint.Sample.getPersonCustomer}`;
                const response = await ApiService("get", endpoints);
                let result = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                    data: response?.reply || [],
                };

                if (response.status) {
                    result = {
                        status: true,
                        message: response.message,
                        data: response.reply,
                    };
                } else {
                    result.message = response.message;
                }
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    };
    /**
     * Numuneyi alan Şirket Ekleme
     * @param {string} customerId Müşteri ID
     * @param {string} name  Adı
     */
    getPersonCustomerCreate = ({customerId, name}) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await ApiService(
                    "post",
                    Endpoint.Sample.getPersonCustomerCreate,
                    {customerId, name}
                );
                let responseResult = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                };

                if (response.status) {
                    responseResult = {
                        status: true,
                        message: response.message,
                    };
                } else {
                    responseResult.message = response.message;
                }

                resolve(responseResult);
            } catch (error) {
                reject(error);
            }
        });
    };
    /**
     * Numuneyi alan Şirket Güncelleme
     * @param {string} getPersonCustomerId  ID
     * @param {string} customerId Müşteri ID
     * @param {string} name  Adı
     */
    getPersonCustomerUpdate = ({getPersonCustomerId, customerId, name}) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await ApiService(
                    "put",
                    Endpoint.Sample.getPersonCustomerUpdate,
                    {getPersonCustomerId, customerId, name}
                );
                let responseResult = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                };

                if (response.status) {
                    responseResult = {
                        status: true,
                        message: response.message,
                    };
                } else {
                    responseResult.message = response.message;
                }

                resolve(responseResult);
            } catch (error) {
                reject(error);
            }
        });
    };
    /**
     * Numuneyi alan Şirket Silme
     * @param {string} getPersonCustomerId  ID

     */
    getPersonCustomerDelete = (getPersonCustomerId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await ApiService(
                    "delete",
                    Endpoint.Sample.getPersonCustomerDelete,
                    {getPersonCustomerId}
                );
                let responseResult = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                };

                if (response.status) {
                    responseResult = {
                        status: true,
                        message: response.message,
                    };
                } else {
                    responseResult.message = response.message;
                }

                resolve(responseResult);
            } catch (error) {
                reject(error);
            }
        });
    };
    /**
     *
     * @param {string} sampleId  - Raporun ids
     * @param {string} workOrderId  - iş Ids
     * @param {string} fileName  - Raporun adı
     * @param {boolean} mailStatus - Mail gönderimi evet/hayır
     */
    sendReportMail = ({
                          sampleId = null,
                          workOrderId = null,
                          fileName,
                          mailStatus = true,
                      }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await ApiService(
                    "post",
                    Endpoint.Sample.sendReportMail,
                    {sampleId, workOrderId, fileName, status: mailStatus}
                );
                let responseResult = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                };

                if (response.status) {
                    responseResult = {
                        status: true,
                        message: response.message,
                    };
                } else {
                    responseResult.message = response.message;
                }

                resolve(responseResult);
            } catch (error) {
                reject(error);
            }
        });
    };
    /**
     * Numune izleme listesi
     * @param {number} limit
     * @param {number} page
     * @param {number} startDate
     * @param {number} endDate
     */
    SampleTrackingList = (limit = 0, page = 0, startDate = 0, endDate = 0) => {
        return new Promise(async (resolve, reject) => {
            try {
                const endpoints = `${Endpoint.Sample.sampletracking}/${limit}/${page}/${startDate}/${endDate}`;
                const response = await ApiService("get", endpoints);

                let result = {
                    status: false,
                    message: "Bir hata meydana geldi.",
                    response: {
                        details: {},
                        data: [],
                    },
                };
                if (response.status) {
                    result = {
                        status: true,
                        message: response.message,
                        response: {
                            details: response.reply.pagination,
                            data: response.reply.posts,
                        },
                    };
                } else {
                    result.message = response.message;
                }
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    };
}

export default new Sample();
