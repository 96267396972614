import React, { Component } from "react";

import PageHeader from "../../../Components/UI/PageHeader";

import { NavLink, Link } from "react-router-dom";

import Table from "../../../Components/UI/Table";
import Export from "../../../Components/Export/Export";
import Helper from "../../../Helper";
import DataService from "../../../Provider/Service/DataService";
import {
  Card,
  Modal,
  Button,
  Tooltip,
  Form,
  Input,
  Select,
  Space,
  Spin,
  Tag,
} from "antd";

import { PlusOutlined } from "@ant-design/icons";
import _ from "lodash";
const { Option } = Select;
const statusArray = [
  {
    id: 0,
    name: "Talep Alındı",
    color: "#fd6205",
  },
  {
    id: 1,
    name: "Numune Yolda",
    color: "#fe9835",
  },
  {
    id: 2,
    name: "Numune Ulaştı",
    color: "#ffce0b",
  },
  {
    id: 3,
    name: "Ödeme Bekliyor",
    color: "#1abc9c",
  },
  {
    id: 4,
    name: "Numune Analizde",
    color: "#9894ff",
  },
  {
    id: 5,
    name: "Rapolanacak",
    color: "#fd54ff",
  },
  {
    id: 6,
    name: "Raporlandı",
    color: "#cdc9ff",
  },
  {
    id: 7,
    name: "Muhasebe",
    color: "#9bd006",
  },
  {
    id: 8,
    name: "İşlem Tamamlandı",
    color: "#1cb451",
  },
];
const statusFilterArray = [
  {
    value: 0,
    text: "Talep Alındı",
  },
  {
    value: 1,
    text: "Numune Yolda",
  },
  {
    value: 2,
    text: "Numune Ulaştı",
  },
  {
    value: 3,
    text: "Ödeme Bekliyor",
  },
  {
    value: 4,
    text: "Numune Analizde",
  },
  {
    value: 5,
    text: "Raporlanacak",
  },
  {
    value: 6,
    text: "Raporlandı",
  },
  {
    value: 7,
    text: "Muhasebe",
    color: "#9bd006",
  },
  {
    value: 8,
    text: "İşlem Tamamlandı",
  },
];
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data: [],
      loading: true,
      searchText: "",
      searchedColumn: "",
      tableColumns: [
        {
          title: "Rapor No",
          dataIndex: "report_no",
          key: "report_no",
          ...Helper.getColumnSearchProps(
            "report_no",
            this.handleSearch,
            this.handleReset,
            "Rapor No"
          ),
          sorter: (a, b) => a.report_no - b.report_no,
        },
        {
          title: "Kayıt No",
          dataIndex: "registry_no",
          key: "registry_no",
          ...Helper.getColumnSearchProps(
            "registry_no",
            this.handleSearch,
            this.handleReset,
            "Kayıt  No"
          ),
          sorter: (a, b) => a.registry_no - b.registry_no,
        },
        {
          title: "Tip",
          dataIndex: "work_order_type_name",
          key: "work_order_type_name",
          ...Helper.getColumnSearchProps(
            "work_order_type_name",
            this.handleSearch,
            this.handleReset,
            "Tip"
          ),
          sorter: (a, b) => a.work_order_type_name - b.work_order_type_name,
        },
        {
          title: "LAB Adı",
          dataIndex: "labCode",
          key: "labCode",
          ...Helper.getColumnSearchProps(
            "labCode",
            this.handleSearch,
            this.handleReset,
            "LAB Adı"
          ),
          sorter: (a, b) => a.labCode.localeCompare(b.labCode),
        },
        {
          title: "İş Kolu",
          key: "sectorName",
          dataIndex: "sectorName",
          ...Helper.getColumnSearchProps(
            "sectorName",
            this.handleSearch,
            this.handleReset,
            "İş Kolu"
          ),
          sorter: (a, b) => a.sectorName.localeCompare(b.sectorName),
        },
        {
          title: "Detay Bilgi",
          key: "get_detail",
          dataIndex: "get_detail",
          ...Helper.getColumnSearchProps(
            "get_detail",
            this.handleSearch,
            this.handleReset,
            "Detay Bilgi"
          ),
          sorter: (a, b) => a.sectorName.localeCompare(b.sectorName),
        },
        {
          title: "Numunenin Alındığı Yer",
          dataIndex: "get_part",
          key: "get_part",
          ...Helper.getColumnSearchProps(
            "get_part",
            this.handleSearch,
            this.handleReset,
            "Numunenin Alındığı Yer"
          ),
          sorter: (a, b) => a.get_part.localeCompare(b.get_part),
        },
        {
          title: "Müşteri",
          key: "customerName",
          dataIndex: "customerName",
          ...Helper.getColumnSearchProps(
            "customerName",
            this.handleSearch,
            this.handleReset,
            "Müşteri"
          ),
          sorter: (a, b) => a.customerName.localeCompare(b.customerName),
        },

        {
          title: "Ürün",
          key: "productName",
          dataIndex: "productName",
          ...Helper.getColumnSearchProps(
            "productName",
            this.handleSearch,
            this.handleReset,
            "Ürün"
          ),
          sorter: (a, b) => a.productName.localeCompare(b.productName),
        },
        {
          title: "Operasyon",
          key: "operationName",
          dataIndex: "operationName",
          ...Helper.getColumnSearchProps(
            "operationName",
            this.handleSearch,
            this.handleReset,
            "Operasyon"
          ),
          sorter: (a, b) => a.operationName.localeCompare(b.operationName),
        },
        {
          title: "Tarih",
          key: "date",
          dataIndex: "date",
          ...Helper.getColumnSearchProps(
            "date",
            this.handleSearch,
            this.handleReset,
            "Tarih"
          ),
        },
        {
          title: "Durum",
          dataIndex: "status_id",
          key: "status_id",
          filters: statusFilterArray,
          onFilter: (value, record) => {
            return record.status_id === value + 1;
          },
          render: (status_id, record) => {
            const status = _.find(statusArray, { id: status_id - 1 });
            return (
              <Tag color={status?.color || "red"} key={status_id}>
                {record?.statusName || "Bulunamadı"}
              </Tag>
            );
          },
        },
        {
          title: "Barkod",
          fixed: "right",
          key: "options",
          dataIndex: "options",
          render: (text, record) => (
            <Space size="middle">
              <NavLink
                activeClassName="nav-active"
                exact
                to={`/sample/sample-print/${record.sampleId}`}
              >
                <Button shape="round" type="primary">
                  Yazdır
                </Button>
              </NavLink>
            </Space>
          ),
        },
        {
          title: "Seçenekler",
          fixed: "right",
          key: "options",
          dataIndex: "options",
          render: (text, record) => (
            <Space size="middle">
              <NavLink
                activeClassName="nav-active"
                exact
                to={`/sample/sample-registration/edit/${record.sampleId}`}
              >
                <Button shape="round" type="primary">
                  Devam Et
                </Button>
              </NavLink>
            </Space>
          ),
        },
      ],
    };
  }
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    try {
      const responseSample = await DataService.Sample.SelectAll(0);

      if (responseSample.status) {
        this.setState({
          // data: _.sortBy(responseSample.response.data,item=>item.status_id),
          data: responseSample.response.data,
          loading: false,
        });
      } else {
        Helper.ErrorMessage(responseSample.message);
      }
    } catch (error) {
      Helper.ErrorMessage(error);
    }
  };
  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  closeModal = (e) => {
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <div>
        <div className="pageHeaderWrapper">
          <PageHeader title="Numune Kayıt Listesi" />
          <div className="pageHeaderOptions">
            <Space>
            <Export
                data={this.state?.exportData || this.state.data}
                columns={[...this.state.tableColumns]}
                tableName={"Numune Kayıt Listesi"}
                dates={["tarih"]}
              />
            <NavLink
            activeClassName="nav-active"
            exact
            to="/sample/sample-registration/add"
          >
            <Button className="btn-add" shape="round">
              <PlusOutlined />
              Yeni Numune Kayıt
            </Button>
          </NavLink>
            </Space>
            </div>
     
        </div>

        <Spin spinning={this.state.loading}>
          <Card bordered={false} className="cardTable">
            <Table columns={this.state.tableColumns} data={this.state.data} pagination={true} />
          </Card>
        </Spin>
      </div>
    );
  }
}

export default index;
